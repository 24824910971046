import { axiosClient } from "@/api/axios";

export function getAllUsers() {
  return axiosClient.get("users");
}

export function getSingleUser(userId, infoType, semesterId) {
  return axiosClient.get(
    `users/${userId}?info=${infoType}&semester=${semesterId}`
  );
}

export function deleteAllUserLesson(userId) {
  return axiosClient.delete(`users/${userId}/lessons/delete-all`);
}

export function deleteUserSingleLesson(userId, courseInstanceId) {
  return axiosClient.delete(
    `users/${userId}/lessons/${courseInstanceId}/delete`
  );
}
