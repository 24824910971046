import { useDateFormat } from "@vueuse/core";
import { formatDuration, intervalToDuration } from "date-fns";

export function formatDate(date, format = "YYYY-MM-DD") {
  if (!date) return "";
  return useDateFormat(date, format).value;
}

export function getGendate() {
  return formatDate(new Date(), "YYYYMMDD_HHmmss");
}

export function formatDurationLong(durationInMilliSec) {
  const duration = intervalToDuration({ start: 0, end: durationInMilliSec });
  const formattedDuration = formatDuration(duration);
  return formattedDuration;
}

export function formatDurationShort(durationInMilliSec) {
  const duration = intervalToDuration({ start: 0, end: durationInMilliSec });
  const options = {
    format: formatArray(durationInMilliSec),
    zero: true,
    delimiter: ":",
    locale: {
      formatDistance: (_token, count) => zeroPad(count),
    },
  };
  const formattedDuration = formatDuration(duration, options);
  return formattedDuration;
}

export function onlyDate(date) {
  return date.split("T")[0];
}

export function onlyTime(date) {
  return date.split("T")[1].split(".")[0];
}

export function getYear(date) {
  return date.split("-")[0];
}

export function semesterFromDate(date) {
  let startDate = onlyDate(date);
  let year = startDate.split("-")[0];
  let month = startDate.split("-")[1];
  let t = month < 6 ? 1 : 2;
  return year + " - T" + t;
}

export function shortSemesterFromDate(date) {
  if (date.split("-")[1] == "01") return "T1";
  else return "T2";
}

function zeroPad(num) {
  return num.toString().padStart(2, "0");
}

function formatArray(durationInMilliSec) {
  const arr =
    durationInMilliSec > 3600000
      ? ["hours", "minutes", "seconds"]
      : ["minutes", "seconds"];
  return arr;
}
