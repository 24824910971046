import { ExportApi } from "@/api";
import { downloadFile } from "@/support/file";

export async function exportLessons() {
  const { data } = await ExportApi.exportLessons();
  downloadFile(data, "Lessons report has been correctly generated");
}

export async function getSemesterInfo(selectedSemester) {
  const { data } = await ExportApi.getSemesterInfo(selectedSemester);
  downloadFile(data, "Semester report has been correctly generated");
}
