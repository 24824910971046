<template>
  <v-dialog v-model="show" width="1000px">
    <v-card>
      <v-card-title>
        Enrollment results
        <v-spacer />
        <v-btn icon @click="show = false">
          <v-icon> mdi-close </v-icon>
        </v-btn>
      </v-card-title>
      <v-responsive height="700px" class="overflow-y-auto">
        <v-card-text>
          <v-expansion-panels accordion>
            <v-expansion-panel
              v-for="(item, i) in items"
              :key="i"
              :disabled="item.error"
            >
              <v-expansion-panel-header v-slot="{ open }">
                <v-row no-gutters>
                  <v-col cols="5">
                    {{ item.student }}
                  </v-col>
                  <v-col cols="7">
                    <v-row v-if="!open" no-gutters>
                      <v-col v-if="item.error" cols="12">
                        <v-icon color="red" class="mx-2">
                          mdi-alert-circle-outline
                        </v-icon>
                        {{ item.error }}
                      </v-col>
                      <v-col v-else cols="6">
                        <v-icon color="success">
                          mdi-check-circle-outline
                        </v-icon>
                        {{ item.results.success.length }}
                      </v-col>
                      <v-col cols="6">
                        <v-icon color="warning">
                          mdi-alert-rhombus-outline
                        </v-icon>
                        {{ item.results.failed.length }}
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-card flat>
                  <v-card-text>
                    <v-simple-table>
                      <template #default>
                        <thead>
                          <tr>
                            <th class="text-left">Lesson</th>
                            <th class="text-center">Status</th>
                            <th class="text-left">Message</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="(enroll, j) in item.results.success.concat(
                              item.results.failed
                            )"
                            :key="j"
                          >
                            <td>{{ enroll.course }}</td>
                            <td class="text-center">
                              <v-icon
                                :color="enroll.isFailed ? 'warning' : 'success'"
                              >
                                {{
                                  enroll.isFailed
                                    ? "mdi-alert-rhombus-outline"
                                    : "mdi-check-circle-outline"
                                }}
                              </v-icon>
                            </td>
                            <td>{{ enroll.message }}</td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-card-text>
                </v-card>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-card-text>
      </v-responsive>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "EnrollStudentsConfirmationDialog",
  props: {
    value: { type: Boolean, default: false },
    enrollmentResults: {
      type: Array,
      default: () => [],
    },
  },
  emits: ["input"],
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    items() {
      return this.enrollmentResults.map((item) => {
        const { failed, success } = item.enrollResults.reduce(
          (acc, enroll) => {
            if (enroll.isFailed) {
              acc.failed.push(enroll);
            } else {
              acc.success.push(enroll);
            }
            return acc;
          },
          { failed: [], success: [] }
        );

        return {
          student: item.student,
          results: {
            failed,
            success,
          },
          error: item.error,
        };
      });
    },
  },
};
</script>
