import Store from "@/store";
import Router from "@/router";

export default class Utils {
  static handle_401(err) {
    if (err.response && err.response.status == 401) Utils.logout();
  }

  static mapError(err) {
    let text = "Error occurred, please try later.";
    if (err.response && err.response.status == 400)
      text = err.response.data.message;
    if (err.response && err.response.status == 401)
      text =
        "You are not authorized for the requested operation, please do login again.";
    if (err.response && err.response.status == 403)
      text =
        "You don't have permissions for the requested operation, please contact the administrator.";
    if (err.response && err.response.status == 404)
      text = "Resource not found, please contact the administrator.";
    if (err.response && err.response.status == 413)
      text = "Payload too large, please contact the administrator.";
    let callback = () => {};
    if (err.response && err.response.status == 401)
      callback = () => Utils.logout();
    let payload = {
      title: "Error",
      text: text,
      callback: callback,
    };
    return payload;
  }

  static isBlank(str) {
    return !str || str.trim().length == 0;
  }

  static isObject(obj) {
    return Object.prototype.toString.call(obj) === "[object Object]";
  }

  static isString(x) {
    return Object.prototype.toString.call(x) === "[object String]";
  }

  static isNull(obj) {
    if (obj === undefined || obj === null) return true;
    if (Utils.isString(obj) && obj.trim().length == 0) return true;
    return false;
  }

  static clone(src) {
    let obj = {};
    for (var k in src) {
      obj[k] = src[k];
    }
    return obj;
  }

  static cloneArray(src) {
    let array = [];

    for (var obj of src) {
      array.push(Utils.clone(obj));
    }

    return array;
  }

  static getQueryParameter(name) {
    var regexS = "[\\?&]" + name + "=([^&#]*)";
    var regex = new RegExp(regexS);
    var results = regex.exec(window.location.href);
    if (results == null) return "";
    else return results[1];
  }

  static addQueryParameter(key, value) {
    var currUrl = new URL(window.location.href);
    currUrl.searchParams.set(key, value);
    window.history.replaceState("", "", currUrl);
  }

  static removeQuery() {
    let url = window.location.href;
    let urlWithoutQuery = url.split("?")[0];
    window.history.replaceState("", "", urlWithoutQuery);
  }

  static reload() {
    window.location.reload(true);
  }

  static logout() {
    Store.dispatch("user/setDefaultState");
    Store.dispatch("navigation/setDefaultState");
    Router.push({ name: "Login" });
  }

  static compareYMDDates(dateString1, dateString2) {
    let dateInt1 =
      parseInt(dateString1.substring(0, 4)) * 10000 +
      parseInt(dateString1.substring(5, 7)) * 100 +
      parseInt(dateString1.substring(8));
    let dateInt2 =
      parseInt(dateString2.substring(0, 4)) * 10000 +
      parseInt(dateString2.substring(5, 7)) * 100 +
      parseInt(dateString2.substring(8));
    return dateInt1 - dateInt2;
  }

  static intToDate(int) {
    let s = int.toString();

    return (
      s.substring(0, 4) + "-" + s.substring(4, 6) + "-" + s.substring(6, 8)
    );
  }

  static dateToInt(date) {
    return (
      parseInt(date.substring(0, 4)) * 10000 +
      parseInt(date.substring(5, 7)) * 100 +
      parseInt(date.substring(8, 10))
    );
  }

  static parsePercentile(str) {
    let value = undefined;
    if (typeof str != "string") return undefined;
    if (str.length > 0 && str.charAt(str.length - 1) == ".") return undefined;
    if (str.length > 0 && str.charAt(str.length - 1) == "%") {
      str = str.substring(0, str.length - 1);
      if (isNaN(str) || isNaN(parseFloat(str))) return undefined;
      value = parseFloat(str);
      if (value <= 0 || value >= 100) return undefined;
    } else {
      if (isNaN(str) || isNaN(parseFloat(str))) return undefined;
      value = parseFloat(str) * 100;
      if (value <= 0 || value >= 100) return undefined;
    }
    return value;
  }

  static range(start, count) {
    let a = [];
    for (var i = start; i < start + count; i++) a.push(i);
    return a;
  }

  static downloadFile(url, name) {
    const link = document.createElement("a");
    link.href = url;
    link.download = name;
    link.click();
    link.remove();
  }

  static toHex(bytes) {
    return [...new Uint8Array(bytes)]
      .map((x) => x.toString(16).padStart(2, "0"))
      .join("");
  }

  static base64ToBlob(base64, type) {
    const byteCharacters = atob(base64);
    const byteNumbers = new Array(byteCharacters.length);

    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);

    return new Blob([byteArray], { type: type });
  }

  static randomHex(length) {
    let zeros = "";
    for (var i = 0; i < length; i++) zeros = `0${zeros}`;
    let random_array = Uint8Array.from(zeros);
    window.crypto.getRandomValues(random_array);
    return Utils.array2hex(random_array);
  }

  static array2hex(list) {
    let res = [];
    for (var b of list) res.push(Utils.byte2hex(b));
    return res.join("");
  }

  static byte2hex(b) {
    const digits = "0123456789abcdef";
    let l = b % 16;
    let h = (b - l) / 16;
    return `${digits[h]}${digits[l]}`;
  }

  static saveChallenge(code_verifier, oauthToken) {
    let sid = oauthToken;
    if (!sid) return;
    var challengeListJson = Utils.readCookie("challengeList");
    if (challengeListJson) {
      let challengeList = JSON.parse(challengeListJson);
      while (challengeList.length >= 5) challengeList.shift();
      challengeList.push({ sid: sid, code_verifier: code_verifier });
      Utils.createCookie("challengeList", JSON.stringify(challengeList), 0);
    } else {
      let challengeList = [{ sid: sid, code_verifier: code_verifier }];
      Utils.createCookie("challengeList", JSON.stringify(challengeList), 0);
    }
  }

  static gup(name) {
    name = name.replace(/[[]/, "\\[").replace(/[\]]/, "\\]");
    var regexS = "[\\?&]" + name + "=([^&#]*)";
    var regex = new RegExp(regexS);
    var results = regex.exec(window.location.href);
    if (results == null) return "";
    else return results[1];
  }

  static readCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(";");
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == " ") c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  }

  static createCookie(name, value, days) {
    let expires = "";
    if (days) {
      var date = new Date();
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      expires = "; expires=" + date.toGMTString();
    }
    document.cookie = name + "=" + value + expires + "; path=/";
  }

  static makeQuery(obj) {
    let q = `?`;
    for (var key in obj) {
      if (obj[key] != undefined) q = `${q}&${key}=${obj[key]}`;
    }
    if (q.length == 1) return ``;
    return q;
  }

  static first(list) {
    if (!list || list.length == 0) return undefined;
    return list[0];
  }
}
