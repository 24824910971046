import YourFavourites from "@/views/YourFavourites.vue";
import Test from "@/views/TestPage.vue";
import Team from "@/views/TeamPage.vue";
import YourLessons from "@/views/YourLessons.vue";
import EnrollStudents from "@/views/EnrollStudent.vue";

export default [
  {
    path: "/",
    redirect: "/login",
    name: "Home",
    meta: {
      sidebar: false,
      header: false,
      paddingZero: true,
    },
  },
  {
    path: "/login",
    name: "Login",
    component: () => {
      return import(
        /* webpackChunkName: "login" */ "@/views/login/LoginPage.vue"
      );
    },
    meta: {
      sidebar: false,
      header: false,
      paddingZero: true,
    },
  },
  {
    path: "/login/admin",
    name: "AdminLogin",
    component: () => {
      return import(
        /* webpackChunkName: "login" */ "@/views/login/AdminLoginPage.vue"
      );
    },
    meta: {
      sidebar: false,
      header: false,
      paddingZero: true,
    },
  },
  {
    path: "/dashboard",
    name: "dashboard",
    component: () => {
      return import(
        /* webpackChunkName: "dashboard" */ "@/views/dashboard/RouteDashboard.vue"
      );
    },
    meta: {
      requiresAuth: true,
      sidebar: true,
      header: true,
    },
  },
  {
    path: "/lessons",
    name: "lessons-catalog",
    component: () => {
      return import(
        /* webpackChunkName: "lesson" */ "@/views/lessons/RouteLessons.vue"
      );
    },
    meta: {
      requiresAuth: true,
      sidebar: true,
      header: true,
      noFillHeight: true,
    },
  },
  {
    path: "/lessons/create",
    name: "lesson-create",
    component: () => {
      return import(
        /* webpackChunkName: "lesson" */ "../views/lessons/LessonCreate.vue"
      );
    },
    props: true,
    meta: {
      requiresAuth: true,
      sidebar: true,
      header: true,
    },
  },
  {
    path: "/lessons/:id",
    name: "lesson-single",
    component: () => {
      return import(
        /* webpackChunkName: "lesson" */ "@/views/lessons/LessonOverview.vue"
      );
    },
    props: true,
    meta: {
      requiresAuth: true,
      sidebar: true,
      header: true,
      noFillHeight: true,
    },
    children: [
      {
        path: "overview",
        name: "lesson-overview",
        component: () => {
          return import(
            /* webpackChunkName: "lesson" */ "@/views/lessons/LessonOverviewInfo.vue"
          );
        },
        props: true,
        meta: {
          requiresAuth: true,
          sidebar: true,
          header: true,
          noFillHeight: true,
        },
      },
      {
        path: "students",
        name: "lesson-students",
        component: () => {
          return import(
            /* webpackChunkName: "lesson" */ "@/views/lessons/LessonOverviewStudents.vue"
          );
        },
        props: true,
        meta: {
          requiresAuth: true,
          sidebar: true,
          header: true,
          noFillHeight: true,
        },
      },
    ],
  },
  {
    path: "/lessons/:id/course",
    name: "lesson",
    component: () => {
      return import(
        /* webpackChunkName: "lesson" */ "@/views/lessons/LessonPage.vue"
      );
    },
    props: true,
    meta: {
      requiresAuth: true,
      sidebar: true,
      header: true,
    },
  },
  {
    path: "/lessons/:courseId/test",
    name: "test",
    component: Test,
    props: true,
    meta: {
      requiresAuth: true,
      sidebar: false,
      header: false,
    },
  },
  {
    path: "/lessons/:id(\\d+)/edit/test",
    name: "lesson-edit-test",
    component: () => {
      return import(
        /* webpackChunkName: "lesson" */ "../views/lessons/LessonEditTest.vue"
      );
    },
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/lessons/:id(\\d+)/edit/info",
    name: "lesson-edit-info",
    component: () => {
      return import(
        /* webpackChunkName: "lesson" */ "../views/lessons/LessonEditGeneralInfo.vue"
      );
    },
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/user/learning",
    name: "your-learning",
    component: () => {
      return import(
        /* webpackChunkName: "your-learning" */ "../views/YourLearningView.vue"
      );
    },
    meta: {
      requiresAuth: true,
      sidebar: true,
      header: true,
      noFillHeight: true,
      semesterSelection: ["USER"],
    },
  },
  {
    path: "/user/favourites",
    name: "your-favourites",
    component: YourFavourites,
    meta: {
      requiresAuth: true,
      sidebar: true,
      header: true,
      noFillHeight: true,
    },
  },
  {
    path: "/user/lessons",
    name: "your-lessons",
    component: YourLessons,
    meta: {
      requiresAuth: true,
      sidebar: true,
      header: true,
      semesterSelection: ["MANAGER", "TEACHER"],
    },
  },
  {
    path: "/questions",
    name: "all-questions",
    component: () => {
      return import(
        /* webpackChunkName: "question" */ "@/views/AllQuestions.vue"
      );
    },
    meta: {
      requiresAuth: true,
      sidebar: true,
      header: true,
    },
  },
  {
    path: "/questions/:id",
    name: "question",
    component: () => {
      return import(
        /* webpackChunkName: "question" */ "@/views/QuestionPage.vue"
      );
    },
    props: true,
    meta: {
      requiresAuth: true,
      sidebar: true,
      header: true,
    },
  },
  {
    path: "/users",
    name: "users-list",
    component: () => {
      return import(
        /* webpackChunkName: "user" */ "@/views/users/UsersListView.vue"
      );
    },
    meta: {
      requiresAuth: true,
      sidebar: true,
      header: true,
    },
  },
  {
    path: "/users/:studentId/student",
    name: "student-info",
    component: () => {
      return import(
        /* webpackChunkName: "user" */ "@/views/users/StudentInfo.vue"
      );
    },
    props: true,
    meta: {
      requiresAuth: true,
      sidebar: true,
      header: true,
      semesterSelection: ["ADMIN", "MANAGER", "TEACHER"],
    },
  },
  {
    path: "/users/:teacherId/teacher",
    name: "teacher-info",
    component: () => {
      return import(
        /* webpackChunkName: "user" */ "@/views/users/TeacherInfo.vue"
      );
    },
    props: true,
    meta: {
      requiresAuth: true,
      sidebar: true,
      header: true,
      semesterSelection: ["ADMIN", "MANAGER", "TEACHER"],
    },
  },
  {
    path: "/mngm/students/enroll",
    name: "enroll-students",
    component: EnrollStudents,
    meta: {
      requiresAuth: true,
      sidebar: true,
      header: true,
      noFillHeight: true,
    },
  },
  {
    path: "/team",
    name: "team",
    component: Team,
    meta: {
      requiresAuth: true,
      sidebar: true,
      header: true,
      semesterSelection: ["ADMIN", "MANAGER"],
    },
  },
  {
    path: "/documentation",
    name: "documentation",
    component: () => {
      return import(
        /* webpackChunkName: "documentation" */ "@/views/DocumentationPage.vue"
      );
    },
    meta: {
      requiresAuth: true,
      sidebar: true,
      header: true,
      noFillHeight: true,
      semesterSelection: ["ADMIN", "MANAGER", "TEACHER", "USER"],
    },
  },
  {
    path: "/need-help",
    name: "need-help",
    component: () => {
      return import(
        /* webpackChunkName: "need-help" */ "../views/NeedHelpPage.vue"
      );
    },
    meta: {
      requiresAuth: true,
      sidebar: true,
      header: true,
    },
  },
];
