import Utils from "@/support/utils.js";
import { showSuccessSnackbar } from "@/support/snackbar";

export function downloadFile(
  data,
  snackbarMessage = "File has been correctly generated"
) {
  const blob = Utils.base64ToBlob(data.file, "application/octet-stream");
  let url = URL.createObjectURL(blob);
  Utils.downloadFile(url, data.name);
  showSuccessSnackbar({ message: snackbarMessage });
}
