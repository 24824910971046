import { axiosClient } from "@/api/axios";

export async function getCourses(semesterId) {
  return axiosClient.get(`team/courses/${semesterId}`);
}

export async function getStudents(semesterId) {
  return axiosClient.get(`team/students/${semesterId}`);
}

export async function getTeachers(semesterId) {
  return axiosClient.get(`team/teachers/${semesterId}`);
}
