<template>
  <section class="h-100 w-100">
    <div class="h-100 w-100">
      <div class="h-100 w-100 pa-5 pb-10">
        <v-card outlined class="h-100">
          <v-card-title>
            My Lessons
            <v-spacer />
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              color="hsl(31, 83%, 57%)"
              hide-details
            />
          </v-card-title>
          <div
            style="height: calc(100% - 100px)"
            class="w-100 d-flex flex-start flex-wrap relative"
          >
            <v-data-table
              class="h-100 w-100 overflow-auto"
              :fixed-header="true"
              :headers="coursesHeaders"
              :search="search"
              :items="lessons"
              :expanded.sync="expanded"
              item-key="id"
              show-expand
            >
              <template #[`item.name`]="{ item }">
                <div>{{ item.name }}</div>
              </template>

              <template #[`item.data-table-expand`]="{ expand, isExpanded }">
                <v-tooltip top>
                  <template #activator="{ on, attrs }">
                    <div
                      v-bind="attrs"
                      class="d-flex pointer"
                      v-on="on"
                      @click="
                        expand(!isExpanded);
                        resetInfo();
                      "
                    >
                      <v-icon class="c-iasonOrange font-25"
                        >mdi-account-group</v-icon
                      >
                      <v-icon class="c-lightBlue font-25"
                        >mdi-chevron-down</v-icon
                      >
                    </div>
                  </template>
                  <span class="font-12">Toggle students list</span>
                </v-tooltip>
              </template>

              <template #expanded-item="{ headers, item }">
                <td
                  :colspan="headers.length"
                  class="studentList overflow-auto"
                  style="max-height: 50%"
                >
                  <ul class="pa-3" style="max-height: 250px">
                    <li
                      v-if="item.students.length == 0"
                      class="studentLine pa-2 d-flex justify-space-between align-center reset-list-style"
                      style="width: 700px"
                    >
                      <h2 class="font-12 font-weight-regular c-lightBlue">
                        No Students Enrolled
                      </h2>
                    </li>
                    <li
                      v-for="(student, index) in item.students"
                      :key="index"
                      class="studentLine pa-2 d-flex justify-space-between align-center reset-list-style lightgrey-border-bottom"
                      style="width: 700px"
                    >
                      <span
                        class="mr-5 d-flex align-center"
                        style="width: 250px"
                      >
                        <span class="font-15">{{
                          student.name + " " + student.surname
                        }}</span>
                      </span>
                      <div class="d-flex justify-start align-center mr-5">
                        <v-progress-linear
                          class="mr-1"
                          style="width: 100px"
                          :color="
                            progressBarColor(
                              progressCalc(student.progress, item.lessons)
                            )
                          "
                          background-color="#F4F6F9"
                          buffer-value="100"
                          :value="progressCalc(student.progress, item.lessons)"
                          height="5"
                        >
                        </v-progress-linear>
                        <div class="font-10">
                          {{
                            progressCalc(
                              student.progress,
                              item.lessons
                            ).toFixed()
                          }}%
                        </div>
                      </div>

                      <v-tooltip v-if="!testInfo" right>
                        <template #activator="{ on, attrs }">
                          <div
                            v-bind="attrs"
                            class="d-flex pointer"
                            style="transition: all 0.5s linear"
                            v-on="on"
                            @click="toggleTestInfo()"
                          >
                            <div
                              v-for="score in student.scores"
                              :key="score"
                              class="mr-5 d-flex"
                              style="transition: all 0.5s linear"
                            >
                              <v-icon
                                :class="
                                  score >= 60 ? 'c-success ' : 'c-danger '
                                "
                                class="font-10"
                                style="transition: all 0.5s linear"
                                >mdi-checkbox-blank-circle</v-icon
                              >
                            </div>
                            <div
                              v-for="j in 3 - student.scores.length"
                              :key="j"
                              class="mr-5 d-flex"
                              style="transition: all 0.5s linear"
                            >
                              <v-icon
                                class="c-lightBlue font-10"
                                style="transition: all 0.5s linear"
                                >mdi-checkbox-blank-circle-outline</v-icon
                              >
                            </div>
                          </div>
                        </template>
                        <span class="font-12">Toggle test results</span>
                      </v-tooltip>

                      <div
                        v-else
                        class="d-flex pointer"
                        style="transition: all 0.5s linear"
                        @click="toggleTestInfo()"
                      >
                        <div
                          v-for="k in student.scores.length"
                          :key="k"
                          class="mr-5 d-flex"
                          style="width: 100px; border-right: 1px solid #3d5463"
                        >
                          <v-icon
                            :class="
                              student.scores[k - 1] >= 60
                                ? 'c-success '
                                : 'c-danger '
                            "
                            class="font-10"
                            style="transition: all 0.5s linear"
                            >mdi-checkbox-blank-circle</v-icon
                          >
                          <div v-if="testInfo" class="testInfo">
                            <span class="font-weight-bold mr-3">T{{ k }}</span>
                            <span>{{ student.scores[k - 1] }} / 100</span>
                          </div>
                        </div>
                        <div
                          v-for="m in 3 - student.scores.length"
                          :key="m"
                          class="mr-5 d-flex"
                          style="width: 100px; border-right: 1px solid #3d5463"
                        >
                          <v-icon
                            class="c-lightBlue font-10"
                            style="transition: all 0.5s linear"
                            >mdi-checkbox-blank-circle-outline</v-icon
                          >
                          <div v-if="testInfo" class="testInfo">
                            <span class="font-weight-bold mr-3"
                              >T{{ student.scores.length + m }}</span
                            >
                            <span>
                              <!-- niente -->
                            </span>
                          </div>
                        </div>
                      </div>
                      <v-tooltip right>
                        <template #activator="{ on, attrs }">
                          <v-btn
                            icon
                            v-bind="attrs"
                            v-on="on"
                            @click="openSendDialog(student)"
                          >
                            <v-icon class="c-darkBlue"
                              >mdi-email-fast-outline</v-icon
                            >
                          </v-btn>
                        </template>
                        <span class="font-12"
                          >Send an email to
                          {{ student.name + " " + student.surname }}</span
                        >
                      </v-tooltip>
                    </li>
                  </ul>
                </td>
              </template>

              <template #[`item.students`]="{ item }">
                {{ item.students.length }}
              </template>

              <template #[`item.progressPercentage`]="{ item }">
                <v-tooltip top>
                  <template #activator="{ on, attrs }">
                    <v-progress-linear
                      v-bind="attrs"
                      class="mr-1"
                      style="width: 100%"
                      :color="progressBarColor(item.progressPercentage)"
                      background-color="#F4F6F9"
                      buffer-value="100"
                      :value="item.progressPercentage"
                      height="5"
                      v-on="on"
                    >
                    </v-progress-linear>
                  </template>
                  <span class="font-10">{{ item.progressPercentage }}%</span>
                </v-tooltip>
              </template>

              <template #[`item.passedQuizNumber`]="{ item }">
                <span v-if="item.students.length > 0">
                  {{ item.passedQuizNumber }} / {{ item.students.length }}</span
                >
                <span v-else>No Students</span>
              </template>

              <template #[`item.scoreAverage`]="{ item }">
                {{
                  item.scoreAverage == -1
                    ? ""
                    : Math.round(item.scoreAverage) + " / 100"
                }}
              </template>

              <template #[`item.actions`]="{ item }">
                <div class="w-100 d-flex justify-space-around align-center">
                  <v-tooltip top>
                    <template #activator="{ on, attrs }">
                      <v-btn
                        icon
                        color="#233642"
                        v-bind="attrs"
                        @click="goToOverview(item.id)"
                        v-on="on"
                      >
                        <v-icon> mdi-information-outline </v-icon>
                      </v-btn>
                    </template>
                    <span class="font-12">Go to Overview</span>
                  </v-tooltip>

                  <v-tooltip top>
                    <template #activator="{ on, attrs }">
                      <v-btn
                        icon
                        color="#233642"
                        v-bind="attrs"
                        @click="goToLesson(item.id)"
                        v-on="on"
                      >
                        <v-icon> mdi-cast-education </v-icon>
                      </v-btn>
                    </template>
                    <span class="font-12">Go to Lesson</span>
                  </v-tooltip>

                  <v-tooltip top>
                    <template #activator="{ on, attrs }">
                      <v-btn
                        icon
                        color="#233642"
                        v-bind="attrs"
                        @click="goToStudents(item.id)"
                        v-on="on"
                      >
                        <v-icon> mdi-account-details </v-icon>
                      </v-btn>
                    </template>
                    <span class="font-12">Go to Student List</span>
                  </v-tooltip>
                </div>
              </template>
            </v-data-table>
          </div>
        </v-card>
      </div>
    </div>
    <v-dialog v-model="showSendDialog" width="600" persistent>
      <v-card>
        <v-card-title>
          Send a message to the user
          <v-spacer />
          <v-btn icon @click="closeSendDialog()"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pt-5">
          <v-row>
            <v-col cols="12">
              <v-textarea
                v-model="emailBody"
                placeholder="Insert your message here..."
                rows="10"
                no-resize
                color="#ec9436"
              >
              </v-textarea>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn text @click="closeSendDialog()">Close</v-btn>
          <v-btn
            :disabled="emailBody.length == 0"
            color="success"
            @click="sendMail()"
            >Send Message</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </section>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import Utils from "@/support/utils.js";
import { progressColor } from "@/support/colors";
import { onlyDate } from "@/support/date";
import { showErrorSnackbar } from "@/support/snackbar";
import { EmailService, YourLessonsService } from "@/services";

export default {
  name: "YourLessonsView",
  components: {},
  data() {
    return {
      viewInfo: {
        text: "My Lessons",
        disabled: false,
        exact: true,
        divider: "/",
        to: { name: `${this.$route.name}` },
        id: `${this.$route.name}`,
      },
      expanded: [],
      search: "",
      testInfo: false,

      loading: true,

      lessons: [],

      coursesHeaders: [
        {
          text: "Name",
          value: "name",
          align: "start",
          class: "font-weight-bold",
          sortable: true,
        },
        { text: "Area", value: "area", align: "center", sortable: false },
        {
          text: "Sections",
          value: "lessons",
          align: "center",
          sortable: false,
        },
        {
          text: "Students",
          value: "students",
          align: "center",
          width: "100px",
          sortable: false,
        },
        { text: "", value: "data-table-expand" },
        {
          text: "Progress %",
          value: "progressPercentage",
          align: "center",
          width: "150px",
          sortable: false,
        },
        {
          text: "Completed",
          value: "passedQuizNumber",
          align: "center",
          sortable: true,
        },
        {
          text: "Score Average",
          value: "scoreAverage",
          align: "center",
          sortable: false,
        },
        {
          text: "Action",
          value: "actions",
          align: "center",
          width: "180px",
          sortable: false,
        },
      ],

      studentHeaders: [
        {
          text: "Email",
          value: "email",
          align: "start",
          class: "c-darkBlue bc-lightGrey",
          width: "20%",
        },
        {
          text: "Progress",
          value: "progress",
          align: "center",
          class: "c-darkBlue bc-lightGrey",
          width: "20%",
        },
      ],

      showSendDialog: false,
      emailBody: "",
      sendToStudent: undefined,
    };
  },
  computed: {
    ...mapGetters("semesters", {
      getSelectedSemester: "getSelectedSemester",
    }),
    ...mapGetters("user", {
      authorization: "authorization",
      userRole: "userRole",
      userMail: "userMail",
      userName: "userName",
    }),
  },
  watch: {
    getSelectedSemester() {
      this.lessons = [];
      this.loading = true;
      this.expanded = [];
      this.search = "";
      this.testInfo = false;
      this.loadInfo();
    },
  },
  created: async function () {
    this.setFullHistoryItems(this.viewInfo);
  },
  mounted: async function () {
    this.setIsDataLoaded(true);
    await this.loadInfo();
  },
  async destroyed() {
    this.setIsDataLoaded(false);
  },
  methods: {
    ...mapMutations({
      setCourseTab: "tab/_setCourseTab",
    }),
    ...mapActions({
      setFullHistoryItems: "navigation/setFullHistoryItems",
      setIsDataLoaded: "setIsDataLoaded",
    }),
    loadInfo: async function () {
      try {
        this.lessons = await YourLessonsService.getYourLessons(
          this.getSelectedSemester.value
        );
      } catch (ex) {
        Utils.handle_401(ex);
        showErrorSnackbar({
          code: ex.response.status,
          message: `Something Went Wrong: ${ex.response.data.message}`,
        });
      } finally {
        this.loading = false;
      }
    },
    progressBarColor(progress) {
      return progressColor(progress);
    },
    trim(date) {
      return onlyDate(date);
    },
    goToOverview(id) {
      this.$router.push({ name: "lesson-overview", params: { id: id } });
    },
    goToLesson(id) {
      this.setCourseTab(1);
      this.$router.push({ name: "lesson", params: { id: id } });
    },
    goToStudents(id) {
      this.$router.push({ name: "lesson-students", params: { id: id } });
    },
    progressCalc(chapterCompleted, totalChapters) {
      return (chapterCompleted / totalChapters) * 100;
    },
    toggleTestInfo() {
      var studentLine = document.getElementsByClassName("studentLine");
      if (this.testInfo == false) {
        this.testInfo = true;
        this.on = true;
        for (let line of studentLine) {
          line.style.width = "933px";
        }
      } else if (this.testInfo == true) {
        this.testInfo = false;
        this.on = false;
        for (let line of studentLine) {
          line.style.width = "700px";
        }
      }
    },
    resetInfo() {
      var studentLine = document.getElementsByClassName("studentLine");
      for (let line of studentLine) {
        line.style.width = "700px";
      }
      this.testInfo = false;
    },
    openSendDialog(student) {
      this.sendToStudent = student;
      this.showSendDialog = true;
    },
    closeSendDialog() {
      this.showSendDialog = false;
      this.emailBody = "";
      this.sendToStudent = undefined;
    },
    async sendMail() {
      const request = {
        from: this.userMail,
        fromName: this.userName,
        to: this.sendToStudent.email,
        toName: this.sendToStudent.name,
        body: this.emailBody,
      };
      try {
        await EmailService.sendMailToUser(request);
      } catch (ex) {
        showErrorSnackbar({
          code: ex.response.status,
          message: `Something Went Wrong: ${ex.response.data.message}`,
        });
      } finally {
        this.closeSendDialog();
      }
    },
  },
};
</script>
