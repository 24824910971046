<template>
  <section class="h-100 w-100">
    <span class="bg"></span>
    <div
      class="absolute d-flex align-center justify-center pa-3 pl-5 pr-5 bc-darkBlue"
      style="width: 250px; top: 0; left: 0; z-index: 100"
    >
      <v-icon class="font-24 mr-5 c-lightGrey">mdi-clock-outline</v-icon>
      <span id="countdown" class="c-lightGrey" style="letter-spacing: 5px">{{
        formattedElapsedTime
      }}</span>
    </div>
    <div
      class="absolute d-flex align-center justify-center pa-3 pl-5 pr-5"
      style="
        background-color: #f9b11552 !important;
        top: 0;
        left: 250px;
        z-index: 100;
      "
    >
      <span class="c-white"
        >Please do not refresh the page, otherwise the test will be
        invalidated</span
      >
    </div>
    <div
      class="pt-5 pb-5 pr-5 w-100 h-100 d-flex justify-space-around align-center relative"
    >
      <v-icon class="question-arrow up" @click="prevQuestion($event)"
        >mdi-arrow-up-bold-box</v-icon
      >
      <v-icon class="question-arrow down" @click="nextQuestion($event)"
        >mdi-arrow-down-bold-box</v-icon
      >
      <!-- BLOCCO DOMANDE -->
      <div id="test">
        <v-card
          v-for="(question, index) in test"
          :key="index"
          outlined
          class="question-card"
        >
          <p>Domanda {{ index + 1 }} - {{ question.text }}</p>
          <v-radio-group v-model="question.answer" column>
            <v-radio
              v-if="question.answer1"
              class="answer c-white pa-2"
              :value="1"
              color="#EC9436"
              dark
              active-class="answer-active"
              @click="
                nextButtonQuestion($event);
                updateAnswers();
              "
            >
              <span slot="label" class="spanswer">{{ question.answer1 }}</span>
            </v-radio>
            <v-radio
              v-if="question.answer2"
              class="answer c-white pa-2"
              :value="2"
              color="#EC9436"
              dark
              active-class="answer-active"
              @click="
                nextButtonQuestion($event);
                updateAnswers();
              "
            >
              <span slot="label" class="spanswer">{{ question.answer2 }}</span>
            </v-radio>
            <v-radio
              v-if="question.answer3"
              class="answer c-white pa-2"
              :value="3"
              color="#EC9436"
              dark
              active-class="answer-active"
              @click="
                nextButtonQuestion($event);
                updateAnswers();
              "
            >
              <span slot="label" class="spanswer">{{ question.answer3 }}</span>
            </v-radio>
            <v-radio
              v-if="question.answer4"
              class="answer c-white pa-2"
              :value="4"
              color="#EC9436"
              dark
              active-class="answer-active"
              @click="
                nextButtonQuestion($event);
                updateAnswers();
              "
            >
              <span slot="label" class="spanswer">{{ question.answer4 }}</span>
            </v-radio>
            <v-radio
              v-if="question.answer5"
              class="answer c-white pa-2"
              :value="5"
              color="#EC9436"
              dark
              active-class="answer-active"
              @click="
                nextButtonQuestion($event);
                updateAnswers();
              "
            >
              <span slot="label" class="spanswer">{{ question.answer5 }}</span>
            </v-radio>
          </v-radio-group>
          <v-btn class="expand-button" @click="expandResumeQuestionCard($event)"
            >Expand</v-btn
          >
          <v-btn class="close-button" @click="closeResumeQuestionCard($event)"
            >Close</v-btn
          >
        </v-card>
      </div>
      <!-- INFO BOX -->
      <div
        class="col-2 h-100 pa-0 h-60 ma-0 d-flex flex-column align-center justify-space-between bc-lightBlue"
        style="border-radius: 4px"
      >
        <v-card
          class="col-12 no-flex font-20 text-center c-darkBlue pa-5"
          style="border-bottom-right-radius: 0; border-bottom-left-radius: 0"
          outlined
        >
          <div class="d-flex align-center justify-space-between">
            <h3 class="font-20">Attempt</h3>
            <strong class="font-15">
              {{ (info ? info.attempts + 1 : "") + " / 3" }}</strong
            >
          </div>
          <v-progress-linear
            class="mb-3 mt-3 relative"
            color="#EC9436"
            buffer-value="100"
            :value="info ? ((info.attempts + 1) / 3) * 100 : 0"
            height="5"
          >
            <div class="progress-divider" style="left: 33.33%"></div>
            <div class="progress-divider" style="left: 66.66%"></div>
          </v-progress-linear>
        </v-card>
        <v-progress-circular
          class="m-auto"
          :rotate="-90"
          :size="200"
          :width="30"
          :value="computePercentage()"
          color="#ec9436"
        >
          <small class="font-30">{{ givenAnswers + " / " + questions }}</small>
        </v-progress-circular>
        <div class="col-12 pa-2 no-flex">
          <v-btn
            id="review-test"
            :disabled="disableReviewButton"
            class="col-12 no-flex ma-0 pa-5 bc-iasonOrange"
            style="height: 100px"
            @click="resumeTest()"
            >Review Test</v-btn
          >
          <v-dialog v-model="dialog" persistent width="500">
            <template #activator="{ on }">
              <v-btn
                id="submit-test"
                :disabled="disableSubmitButton"
                class="col-12 mt-2 no-flex ma-0 pa-5 bc-success"
                style="height: 100px"
                :loading="isSubmitting"
                @click="submit(test, testId)"
                v-on="on"
                >Submit Test</v-btn
              >
            </template>
            <v-card style="min-height: 500px">
              <v-card-title class="text-h5 grey bc-lightBlue c-white">
                Results
              </v-card-title>
              <v-divider></v-divider>
              <v-card-text>
                <!-- PASSATO -->
                <div v-if="testPassed == true" class="w-100">
                  <div
                    class="d-flex align-center justify-space-around pt-5 pb-5 mb-10 bc-white"
                    style="z-index: 5"
                  >
                    <div class="d-flex align-center justify-center w-50 h-100">
                      <TestPassedSvg width="50%" height="100%"></TestPassedSvg>
                    </div>
                    <h1
                      class="w-50 text-left font-30"
                      style="line-height: 40px; color: #25ae88"
                    >
                      TEST PASSED
                    </h1>
                  </div>
                  <div class="testResultStats">
                    <div
                      class="mb-3 pb-3 d-flex align-center justify-space-between lightgrey-border-bottom"
                    >
                      <span class="font-24 font-weight-bold">
                        <v-icon class="font-24">mdi-timer-outline</v-icon>
                        TIME:
                      </span>
                      <span class="font-24">{{ formattedFinalTime }}</span>
                    </div>
                    <div
                      class="mb-3 pb-3 d-flex align-center justify-space-between lightgrey-border-bottom"
                    >
                      <span class="font-24 font-weight-bold">
                        <v-icon class="font-24">mdi-counter</v-icon>
                        SCORE:
                      </span>
                      <span class="font-24">{{ result.score }}/100</span>
                    </div>
                    <div
                      class="mb-3 pb-3 d-flex align-center justify-space-between lightgrey-border-bottom"
                    >
                      <span class="font-24 font-weight-bold">
                        <v-icon class="font-24">mdi-trophy-award</v-icon>
                        CREDITS:
                      </span>
                      <span class="font-24">{{ result.credits }}</span>
                    </div>
                    <div
                      class="mb-3 pb-3 d-flex align-center justify-space-between"
                    >
                      <span class="font-24 font-weight-bold">
                        <v-icon class="font-24">mdi-battery-medium</v-icon>
                        ATTEMPTS LEFT:
                      </span>
                      <v-progress-linear
                        class="relative col-4 pa-0"
                        color="#EC9436"
                        background-color="#F4F6F9"
                        buffer-value="100"
                        :value="
                          info ? 100 - ((info.attempts + 1) / 3) * 100 : 0
                        "
                        height="10"
                      >
                        <div
                          class="progress-divider"
                          style="left: 33.33%"
                        ></div>
                        <div
                          class="progress-divider"
                          style="left: 66.66%"
                        ></div>
                      </v-progress-linear>
                    </div>
                  </div>
                </div>
                <!-- NON PASSATO -->
                <div v-else-if="testPassed == false">
                  <div
                    class="d-flex align-center justify-space-around pt-5 pb-5 mb-10"
                    style="z-index: 5"
                  >
                    <div class="d-flex align-center justify-center w-50 h-100">
                      <TestFailedSvg width="50%" height="100%"></TestFailedSvg>
                    </div>
                    <h1
                      class="w-50 text-left font-30"
                      style="line-height: 40px; color: #d75a4a"
                    >
                      TEST FAILED
                    </h1>
                  </div>
                  <!-- RESULTS -->
                  <div class="testResultStats">
                    <div
                      class="mb-3 pb-3 d-flex align-center justify-space-between lightgrey-border-bottom"
                    >
                      <span class="font-24 font-weight-bold">
                        <v-icon class="font-24">mdi-timer-outline</v-icon>
                        TIME:
                      </span>
                      <span class="font-24">{{ formattedFinalTime }}</span>
                    </div>
                    <div
                      class="mb-3 pb-3 d-flex align-center justify-space-between lightgrey-border-bottom"
                    >
                      <span class="font-24 font-weight-bold">
                        <v-icon class="font-24">mdi-counter</v-icon>
                        SCORE:
                      </span>
                      <span class="font-24 c-danger"
                        >{{ result ? result.score : "---" }}/100</span
                      >
                    </div>
                    <div
                      class="mb-3 pb-3 d-flex align-center justify-space-between lightgrey-border-bottom"
                    >
                      <span class="font-24 font-weight-bold">
                        <v-icon class="font-24">mdi-trophy-award</v-icon>
                        CREDITS:
                      </span>
                      <span class="font-24 c-danger">{{
                        result ? result.credits : "---"
                      }}</span>
                    </div>
                    <div
                      class="mb-3 pb-3 d-flex align-center justify-space-between"
                    >
                      <span class="font-24 font-weight-bold">
                        <v-icon class="font-24">mdi-battery-medium</v-icon>
                        ATTEMPTS LEFT:
                      </span>
                      <v-progress-linear
                        class="relative col-4 pa-0"
                        color="#EC9436"
                        background-color="#F4F6F9"
                        buffer-value="100"
                        :value="
                          info ? 100 - ((info.attempts + 1) / 3) * 100 : 0
                        "
                        height="10"
                      >
                        <div
                          class="progress-divider"
                          style="left: 33.33%"
                        ></div>
                        <div
                          class="progress-divider"
                          style="left: 66.66%"
                        ></div>
                      </v-progress-linear>
                    </div>
                  </div>
                </div>
              </v-card-text>
              <v-card-actions>
                <v-btn color="primary" text @click="openSendDialog()">
                  Contact the Teacher
                </v-btn>
                <v-spacer />
                <v-btn
                  :to="{ name: 'dashboard' }"
                  color="primary"
                  text
                  @click="dialog = false"
                >
                  Back To Dashboard
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </div>
      </div>
    </div>
    <v-dialog v-model="showSendDialog" width="600" persistent>
      <v-card>
        <v-card-title>
          Send a message to the teacher
          <v-spacer />
          <v-btn icon @click="closeSendDialog()"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pt-5">
          <v-row>
            <v-col cols="12">
              <v-textarea
                v-model="emailBody"
                placeholder="Insert your message here..."
                rows="10"
                no-resize
                color="#ec9436"
              >
              </v-textarea>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn text @click="closeSendDialog()">Close</v-btn>
          <v-btn
            :disabled="emailBody.length == 0"
            color="success"
            @click="sendMail()"
            >Send Message</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </section>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Utils from "@/support/utils.js";
import TestPassedSvg from "@/components/svg/TestPassedSvg.vue";
import TestFailedSvg from "@/components/svg/TestFailedSvg.vue";
import { showErrorSnackbar } from "@/support/snackbar";
import { TestService, EmailService } from "@/services";

export default {
  name: "TestPage",
  components: {
    TestPassedSvg,
    TestFailedSvg,
  },
  props: {
    courseId: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      viewName: "Test",
      test: [],
      testId: undefined,
      info: undefined,
      questions: undefined,
      givenAnswers: 0,

      /* FRONTEND */
      resumeMode: false,
      resumeButtonDisabled: true,

      /* TIMER */
      elapsedTime: 1200000,
      passedTime: 0,
      finalTime: undefined,
      timer: undefined,

      /* FINAL MODAL */
      testPassed: undefined,
      score: undefined,
      dialog: false,

      result: undefined,

      showSendDialog: false,
      emailBody: "",

      isSubmitting: false,
      isSubmitted: false,
    };
  },
  computed: {
    ...mapGetters("user", {
      authorization: "authorization",
      userId: "userId",
      userRole: "userRole",
      userMail: "userMail",
      userName: "userName",
    }),
    disableSubmitButton() {
      return (
        (this.test ? this.givenAnswers < this.test.length : true) ||
        this.isSubmitted
      );
    },
    disableReviewButton() {
      return this.resumeButtonDisabled || this.isSubmitting || this.isSubmitted;
    },
    formattedElapsedTime() {
      const date = new Date(null);
      date.setSeconds(this.elapsedTime / 1000);
      const utc = date.toUTCString();
      var timeLeft = utc.substr(utc.indexOf(":") - 2, 8);
      this.timerAnimations(timeLeft);
      return timeLeft;
    },
    formattedFinalTime() {
      const date = new Date(null);

      date.setSeconds(this.passedTime / 1000);
      const utc = date.toUTCString();
      var timePassed = utc.substr(utc.indexOf(":") - 2, 8);
      return timePassed;
    },
  },
  async created() {
    await this.loadInfo();
    await this.createTest();
    this.initialTestRender();
    this.startTimer();
  },
  async destroyed() {
    this.setIsDataLoaded(false);
  },
  methods: {
    ...mapActions({
      setIsDataLoaded: "setIsDataLoaded",
    }),
    async loadInfo() {
      try {
        this.info = await TestService.get(this.courseId);
      } catch (ex) {
        if (ex.response.status === 401) {
          Utils.handle_401(ex);
        } else if (ex.response.status === 403) {
          this.$router.push("/dashboard");
        }
        showErrorSnackbar({
          code: ex.response.status,
          message: `Something Went Wrong: ${ex.response.data.message}`,
        });
      }
    },
    async createTest() {
      try {
        const data = await TestService.insert(
          this.courseId,
          this.makeAttempt(this.info)
        );
        this.test = data.questions;
        this.testId = data.id;
        this.questions = this.test.length;
        this.elapsedTime = data.duration * 60000;
        this.setIsDataLoaded(true);
      } catch (ex) {
        if (ex.response.status === 401) {
          Utils.handle_401(ex);
        } else if (ex.response.status === 403) {
          this.$router.push("/dashboard");
        }
        showErrorSnackbar({
          code: ex.response.status,
          message: `Something Went Wrong: ${ex.response.data.message}`,
        });
      }
    },
    async submit(test, id) {
      this.isSubmitting = true;
      try {
        this.result = await TestService.submit(id, test);
        this.testPassed = this.result.passed;
        this.points = this.result.points;
      } catch (ex) {
        showErrorSnackbar({
          code: ex.response.status,
          message: `Something Went Wrong: ${ex.response.data.message}`,
        });
      } finally {
        this.isSubmitting = false;
        this.isSubmitted = true;
        this.stopTimer();
      }
    },
    startTimer() {
      this.timer = setInterval(() => {
        this.elapsedTime -= 1000;
        this.passedTime += 1000;
      }, 1000);
    },
    stopTimer() {
      clearInterval(this.timer);
    },
    timerAnimations(timeLeft) {
      if (timeLeft == "00:00:00") {
        var submitButton = document.getElementById("submit-test");
        this.givenAnswers = this.questions;
        this.stopTimer();
        setTimeout(function () {
          submitButton.click();
        }, 10);
      }
    },
    initialTestRender() {
      var allQuestions = document.getElementsByClassName("question-card");
      allQuestions[0].classList.add("question-card-active");
      allQuestions[0].classList.add("first-question");
      allQuestions[allQuestions.length - 1].classList.add("last-question");

      var spanswere = document.getElementsByClassName("spanswer");
      for (var i = 0; i < spanswere.length; i++) {
        spanswere[i].closest(".v-label").style.width = "90%";
      }
    },
    nextButtonQuestion(event) {
      var thisQuestion = event.target.closest(".question-card");
      if (this.resumeMode == false) {
        if (thisQuestion.classList.contains("last-question")) {
          thisQuestion.classList.add("question-slide-up");
          var firstQuestion =
            document.getElementsByClassName("first-question")[0];
          setTimeout(function () {
            thisQuestion.classList.remove("question-card-active");
            thisQuestion.classList.remove("question-slide-up");
            firstQuestion.classList.add("question-card-active");
            firstQuestion.classList.add("question-from-bottom");
          }, 250);
          setTimeout(function () {
            firstQuestion.classList.remove("question-from-bottom");
          }, 500);
        } else {
          thisQuestion.classList.add("question-slide-up");
          setTimeout(function () {
            thisQuestion.classList.remove("question-slide-up");
            thisQuestion.classList.remove("question-card-active");

            thisQuestion.nextSibling.classList.add("question-card-active");
            thisQuestion.nextSibling.classList.add("question-from-bottom");
          }, 250);
          setTimeout(function () {
            thisQuestion.nextSibling.classList.remove("question-from-bottom");
          }, 500);
        }

        if (this.givenAnswers == this.questions - 1) {
          this.resumeButtonDisabled = false;
        }
      }
    },
    nextQuestion() {
      var activeQuestion = document.getElementsByClassName(
        "question-card-active"
      )[0];
      if (activeQuestion.classList.contains("last-question")) {
        activeQuestion.classList.add("question-slide-up");
        var firstQuestion =
          document.getElementsByClassName("first-question")[0];
        setTimeout(function () {
          activeQuestion.classList.remove("question-card-active");
          activeQuestion.classList.remove("question-slide-up");
          firstQuestion.classList.add("question-card-active");
          firstQuestion.classList.add("question-from-bottom");
        }, 250);
        setTimeout(function () {
          firstQuestion.classList.remove("question-from-bottom");
        }, 500);
      } else {
        activeQuestion.classList.add("question-slide-up");
        setTimeout(function () {
          activeQuestion.classList.remove("question-slide-up");
          activeQuestion.classList.remove("question-card-active");

          activeQuestion.nextSibling.classList.add("question-card-active");
          activeQuestion.nextSibling.classList.add("question-from-bottom");
        }, 250);
        setTimeout(function () {
          activeQuestion.nextSibling.classList.remove("question-from-bottom");
        }, 500);
      }
    },
    prevQuestion() {
      var activeQuestion = document.getElementsByClassName(
        "question-card-active"
      )[0];
      if (activeQuestion.classList.contains("first-question")) {
        activeQuestion.classList.add("question-slide-down");
        var lastQuestion = document.getElementsByClassName("last-question")[0];
        setTimeout(function () {
          activeQuestion.classList.remove("question-card-active");
          activeQuestion.classList.remove("question-slide-down");
          lastQuestion.classList.add("question-card-active");
          lastQuestion.classList.add("question-from-top");
        }, 250);
        setTimeout(function () {
          lastQuestion.classList.remove("question-from-top");
        }, 500);
      } else {
        activeQuestion.classList.add("question-slide-down");
        setTimeout(function () {
          activeQuestion.classList.remove("question-slide-down");
          activeQuestion.classList.remove("question-card-active");

          activeQuestion.previousSibling.classList.add("question-card-active");
          activeQuestion.previousSibling.classList.add("question-from-top");
        }, 250);
        setTimeout(function () {
          activeQuestion.previousSibling.classList.remove("question-from-top");
        }, 500);
      }
    },

    updateAnswers() {
      this.givenAnswers = this.test
        .map((q) => q.answer)
        .filter((a) => a != 0).length;
    },
    computePercentage() {
      return (this.givenAnswers / this.questions) * 100;
    },
    makeAttempt(info) {
      return {
        id: 0,
        enrollmentId: info.enrollmentId,
        passed: false,
        score: 0,
        attemptNumber: info.attempts + 1,
        startTs: null,
        endTs: null,
      };
    },
    resumeTest() {
      this.resumeButtonDisabled = true;
      /* avverto vue che siamo in modalità resume */
      this.resumeMode = true;
      /* FACCIO SPARIRE LE FRECCIE DI NAVIGAZIONE */
      var arrows = document.getElementsByClassName("question-arrow");
      for (var k = 0; k < arrows.length; k++) {
        arrows[k].style.display = "none";
      }

      /* CAMBIO LO STILE DELLE DOMANDE SWITCHANDO ID E CLASSI */
      var testBlock = document.getElementById("test");
      testBlock.removeAttribute("id");
      testBlock.id = "resume";
      var questions = testBlock.children;
      for (var i = 0; i < questions.length; i++) {
        questions[i].classList.add("compressed");
      }

      /* CAMBIO LE DIMENSIONI DI LABEL E SPAN */
      var vLabels = document.getElementsByClassName("v-label");
      for (var u = 0; u < vLabels.length; u++) {
        vLabels[u].style.width = "90%";
      }
      var spansweres = document.getElementsByClassName("spanswer");
      for (var e = 0; e < spansweres.length; e++) {
        spansweres[e].classList.add("text-truncate");
      }

      this.resumeButtonDisabled = true;
    },
    closeResumeQuestionCard() {
      /* COMPRIMO TUTTE LE DOMANDE  */
      var expandedQuestion = document.getElementsByClassName("expanded");
      for (var e = 0; e < expandedQuestion.length; e++) {
        expandedQuestion[e].classList.add("compressed");
        expandedQuestion[e].classList.remove("expanded");
      }
      /* COMPRIMO TUTTE LE RISPOSTE */
      var spansweres = document.getElementsByClassName("spanswer");
      for (var k = 0; k < spansweres.length; k++) {
        spansweres[k].classList.add("text-truncate");
      }
    },
    expandResumeQuestionCard(event) {
      /* RESETTO TUTTE LE DOMANDE ESPANSE */
      this.closeResumeQuestionCard();
      /* ESPANDO LA DOMANDA SELEZIONATA */
      var thisQuestion = event.target.closest(".question-card");
      thisQuestion.classList.remove("compressed");
      thisQuestion.classList.add("expanded");

      /* ESPANDO LE RISPOSTE TRONCATE */
      var spanswere = thisQuestion.querySelectorAll(".text-truncate");
      for (var i = 0; i < spanswere.length; i++) {
        spanswere[i].classList.remove("text-truncate");
      }

      var resumeBlock = document.getElementById("resume");
      resumeBlock.scroll({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    },
    openSendDialog() {
      this.showSendDialog = true;
    },
    closeSendDialog() {
      this.showSendDialog = false;
      this.emailBody = "";
    },
    async sendMail() {
      const request = {
        from: this.userMail,
        fromName: this.userName,
        to: this.info.teacherEmail,
        toName: this.info.teacherName,
        body: this.emailBody,
      };
      try {
        await EmailService.sendMailToUser(request);
      } catch (ex) {
        showErrorSnackbar({
          code: ex.response.status,
          message: `Something Went Wrong: ${ex.response.data.message}`,
        });
      } finally {
        this.emailBody = "";
        this.closeSendDialog();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.v-btn {
  &#review-test,
  &#submit-test {
    color: white !important;
  }
}

.v-btn--disabled {
  &#review-test,
  &#submit-test {
    color: rgba(255, 255, 255, 0.26) !important;
  }
}

.bg {
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  background-image: linear-gradient(
    to right,
    #434343 0%,
    black 100%
  ) !important;
}

.question-arrow {
  position: absolute !important;
  font-size: 75px !important;
  color: $lightGrey !important;
  left: 50px !important;
}

.question-arrow.up {
  top: 25%;
}
.question-arrow.down {
  bottom: 25%;
}

.question-card {
  display: none;
  border: none !important;
}
.question-card-active {
  display: block;
}
.answer {
  border: 1px solid $lightGrey;
  background-color: $darkBlue;
  &:hover {
    border: 2px solid $lightGrey;
    background-color: $lightBlue;
  }
}
.answer-active {
  border: 2px solid $lightGrey;
  background-color: $lightBlue;
}
.question-slide-up {
  animation: questionSlideUp 0.25s ease-out forwards;
}
.question-from-bottom {
  animation: questionFromBottom 0.25s ease-in forwards;
}
.question-from-top {
  animation: questionFromTop 0.25s ease-in forwards;
}
.question-slide-down {
  animation: questionSlideDown 0.25s ease-out forwards;
}

#test {
  width: 70%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  overflow: hidden;
  .question-card {
    width: 100%;
    background-color: transparent;
    margin-bottom: 20px;
    padding: 20px;
    p {
      font-size: 30px;
      color: white;
    }
    .expand-button {
      display: none;
    }
    .close-button {
      display: none;
    }
  }
}

#resume {
  display: flex;
  flex-wrap: wrap;
  overflow: auto;
  width: 100%;
  padding: 30px;
  height: 100%;
  background-color: transparent;
  .question-card.compressed {
    display: block;
    width: 25%;
    height: auto;
    background-color: transparent;
    transition: order 0.3s linear, width 0.3s linear;
    margin-bottom: 20px;
    padding: 20px;
    transition: all 0.2s ease-out;
    order: initial;
    p {
      font-size: 20px;
      width: 100%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      color: white;
    }
    .close-button {
      display: none;
    }
  }
  .question-card.expanded {
    display: block;
    width: 100%;
    height: auto;
    background-color: transparent;
    margin-bottom: 20px;
    transition: order 0.3s linear, width 0.3s linear;
    padding: 20px;
    order: -1;
    padding-bottom: 20px;
    border-bottom: 3px solid $lightGrey;
    p {
      font-size: 30px;
      color: white;
    }
    .expand-button {
      display: none;
    }
  }
}

.progress-divider {
  position: absolute;
  top: 0;
  width: 5px;
  height: 100%;
  background-color: white;
  z-index: 5;
}

@keyframes questionSlideUp {
  0% {
    transform: translateY(0%);
    opacity: 1;
  }
  30% {
    opacity: 0;
  }
  100% {
    transform: translateY(-200%);
  }
}
@keyframes questionFromBottom {
  0% {
    transform: translateY(200%);
    opacity: 0;
  }
  100% {
    transform: translateY(0%);
    opacity: 1;
  }
}
@keyframes questionFromTop {
  0% {
    transform: translateY(-200%);
    opacity: 0;
  }
  100% {
    transform: translateY(0%);
    opacity: 1;
  }
}
@keyframes questionSlideDown {
  0% {
    transform: translateY(0%);
    opacity: 1;
  }
  100% {
    transform: translateY(200%);
    opacity: 0;
  }
}

/* TIMER  --------------------------------------------*/
.timerOneminuteLeft {
  animation: oneMinuteLeft 0.3s linear;
  transition: all 0.3s linear;
}

@keyframes oneMinuteLeft {
  0% {
    transform: translate(0px);
  }
  25% {
    transform: translate(3px);
  }
  50% {
    transform: translate(-3px);
  }
  75% {
    transform: translate(3px);
  }
  100% {
    transform: translate(0px);
  }
}

/* RESULT POP UP ----------------------------------- */
.testResultStats {
  z-index: 1;
  animation: slideDownResults 1s linear;
}

@keyframes slideDownResults {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>
