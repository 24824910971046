<template>
  <v-app>
    <TheSidebar v-if="needSidebar" />
    <TheHeader v-if="needHeader" />
    <v-main class="bc-lightGrey">
      <v-container fluid :class="containerClass">
        <PageLoader v-if="!isDataLoaded" />
        <router-view></router-view>
      </v-container>
    </v-main>
    <TheSnackbar />
  </v-app>
</template>

<script>
import { mapGetters } from "vuex";
import TheHeader from "@/components/TheHeader.vue";
import TheSidebar from "@/components/TheSidebar.vue";
import PageLoader from "@/components/PageLoader.vue";
import TheSnackbar from "@/components/TheSnackbar.vue";

export default {
  name: "App",
  components: {
    TheSidebar,
    TheHeader,
    PageLoader,
    TheSnackbar,
  },
  computed: {
    ...mapGetters({
      isDataLoaded: "isDataLoaded",
    }),
    needHeader() {
      return this.$route.meta.header;
    },
    needSidebar() {
      return this.$route.meta.sidebar;
    },
    containerClass() {
      return {
        "pa-0": this.$route.meta.paddingZero,
        "fill-height": !this.$route.meta.noFillHeight,
      };
    },
  },
};
</script>

<style lang="scss">
html {
  height: 100%;
  overflow: hidden !important;
  body {
    height: 100%;
  }
}
#app {
  height: 100%;
}
</style>
