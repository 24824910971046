<template>
  <v-card color="#233642" height="100px" class="d-flex align-end">
    <v-card-title class="text-h4 white--text" style="width: 100%">
      {{ label }}
      <v-spacer />
      <slot name="action-buttons"> </slot>
    </v-card-title>
  </v-card>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      required: true,
    },
  },
};
</script>
