<template>
  <v-navigation-drawer app permanent expand-on-hover mini-variant>
    <div class="d-flex flex-column justify-space-between" style="height: 100%">
      <div style="height: 100%">
        <!-- logo iason -->
        <v-container class="nav-logo d-flex justify-center">
          <img id="logo-nav-close" src="../assets/logo.png" />
          <img id="logo-nav-open" src="../assets/logo-grande-iason.png" />
        </v-container>
        <v-divider></v-divider>
        <!-- Nav list -->
        <v-list id="nav-list" nav>
          <v-list-item
            v-for="navItem in getNavItems"
            :key="navItem.title"
            link
            :to="navItem.to"
            exact
          >
            <v-list-item-icon>
              <v-icon class="nav-icon">{{ navItem.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="nav-title">{{
              navItem.title
            }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </div>
      <!-- extra -->
      <div>
        <v-list id="nav-extra">
          <v-list-item
            style="visibility: hidden"
            to="/notification"
            link
            disabled
            class="bc-lightBlue opacity-half"
          >
            <v-list-item-icon>
              <v-icon class="nav-icon">mdi-bell</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="nav-title"
              >Notifications <br />
              <span class="font-12">(work in progress..)</span>
            </v-list-item-title>
          </v-list-item>

          <v-list-item :to="{ name: 'need-help' }" link>
            <v-list-item-icon>
              <v-icon class="nav-icon">mdi-help-box-multiple-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="nav-title">Need Help?</v-list-item-title>
          </v-list-item>
        </v-list>
      </div>
    </div>
  </v-navigation-drawer>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "TheSidebar",
  computed: {
    ...mapGetters({
      authorization: "user/authorization",
      userRole: "user/userRole",
    }),
    getNavItems() {
      switch (this.userRole) {
        case "ADMIN":
          return [...this.getAdminItems];
        case "MANAGER":
          return [...this.getManagerItems];
        case "TEACHER":
          return [...this.getTeacherItems];
        default:
          return [...this.getDefaultItems];
      }
    },
    getAdminItems() {
      return [
        {
          icon: "mdi-home",
          to: { name: "dashboard" },
          title: "Dashboard",
        },
        {
          icon: "mdi-monitor",
          to: { name: "lessons-catalog" },
          title: "Lessons",
        },
        {
          icon: "mdi-account-multiple",
          to: { name: "users-list" },
          title: "Users",
        },
        {
          icon: "mdi-calendar-cursor",
          to: { name: "team" },
          title: "Semesters",
        },
        {
          icon: "mdi-folder-account-outline",
          to: { name: "enroll-students" },
          title: "Enroll Students",
        },
      ];
    },
    getManagerItems() {
      return [
        {
          icon: "mdi-home",
          to: { name: "dashboard" },
          title: "Dashboard",
        },
        {
          icon: "mdi-format-list-bulleted",
          to: { name: "lessons-catalog" },
          title: "Lessons Catalogue",
        },
        {
          icon: "mdi-bookmark-multiple",
          to: { name: "your-favourites" },
          title: "My Favourites",
        },
        {
          icon: "mdi-video-box",
          to: { name: "your-lessons" },
          title: "My Lessons",
        },
        {
          icon: "mdi-forum-outline",
          to: { name: "all-questions" },
          title: "Q&A",
        },
        {
          icon: "mdi-account-multiple",
          to: { name: "team" },
          title: "Team",
        },
      ];
    },
    getTeacherItems() {
      return [
        {
          icon: "mdi-home",
          to: { name: "dashboard" },
          title: "Dashboard",
        },
        {
          icon: "mdi-book-open-page-variant-outline",
          to: { name: "your-learning" },
          title: "My Learning",
        },
        {
          icon: "mdi-format-list-bulleted",
          to: { name: "lessons-catalog" },
          title: "Lessons Catalogue",
        },
        {
          icon: "mdi-bookshelf",
          to: { name: "documentation" },
          title: "Documentation",
        },
        {
          icon: "mdi-video-box",
          to: { name: "your-lessons" },
          title: "My Lessons",
        },
        {
          icon: "mdi-forum-outline",
          to: { name: "all-questions" },
          title: "Q&A",
        },
      ];
    },
    getDefaultItems() {
      return [
        {
          icon: "mdi-home",
          to: { name: "dashboard" },
          title: "Dashboard",
        },
        {
          icon: "mdi-book-open-page-variant-outline",
          to: { name: "your-learning" },
          title: "My Learning",
        },
        {
          icon: "mdi-format-list-bulleted",
          to: { name: "lessons-catalog" },
          title: "Lessons Catalogue",
        },
        {
          icon: "mdi-bookshelf",
          to: { name: "documentation" },
          title: "Documentation",
        },
        {
          icon: "mdi-forum-outline",
          to: { name: "all-questions" },
          title: "Q&A",
        },
      ];
    },
  },
  methods: {
    userRoleInitial() {
      var thisUserRole = this.userRole;
      var firstLetter = thisUserRole.charAt(0);
      return firstLetter;
    },
  },
};
</script>

<style lang="scss" scoped>
nav.v-navigation-drawer {
  background-color: $mediumBlue;
  height: 100% !important;
  .v-navigation-drawer__content {
    height: 100%;
    width: 100%;
    display: flex !important;
    flex-direction: column !important;
    justify-content: space-between !important;
    div {
      .nav-logo {
        height: 64px;
        width: auto;
        margin: 0 auto;
        background-color: $darkBlue;
        align-items: center !important;
        #logo-nav-close {
          height: 75%;
          width: auto;
          opacity: 1;
          transition: opacity 0.3s ease-out;
        }
        #logo-nav-open {
          height: 0%;
          width: auto;
          opacity: 0;
          transition: opacity 0.4s ease-in;
        }
      }
      #nav-list {
        margin-top: 10px;
        color: $lightGrey;

        a {
          flex: none;
          .nav-icon {
            color: $lightGrey;
          }

          .nav-title {
            color: $lightGrey;
            font-size: 15px;
            font-weight: 400;
          }
        }
      }
    }
    #nav-extra {
      height: 128px;
      bottom: 0;

      a {
        flex: none;
        .nav-icon {
          color: $lightGrey;
        }

        .nav-title {
          color: $lightGrey;
          font-size: 15px;
          font-weight: 400;
        }
      }
    }
  }
  &:hover {
    .nav-logo {
      justify-content: center !important;

      #logo-nav-close {
        height: 0% !important;
        opacity: 0 !important;
      }
      #logo-nav-open {
        height: 100% !important;
        opacity: 1 !important;
      }
    }

    .userRole {
      display: none !important;
    }

    .userRoleExtended {
      display: block !important;
    }
  }
}
</style>
