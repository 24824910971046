import { CourseEnrollmentApi } from "@/api";
import { showSuccessSnackbar } from "@/support/snackbar";

export async function setProgress100(enrollmentId) {
  const response = await CourseEnrollmentApi.setProgress100(enrollmentId);
  showSuccessSnackbar({
    code: response.status,
    message: `Successfully set progress to 100`,
  });
}

export async function resetTests(enrollmentId) {
  const response = await CourseEnrollmentApi.resetTests(enrollmentId);
  showSuccessSnackbar({
    code: response.status,
    message: `Successfully reset the test`,
  });
}

export async function switchStatus(enrollmentId) {
  const response = await CourseEnrollmentApi.switchStatus(enrollmentId);
  showSuccessSnackbar({
    code: response.status,
    message: `Successfully changed lesson status`,
  });
}

export async function update(enrollmentId, payload) {
  await CourseEnrollmentApi.update(enrollmentId, payload);
}

export async function saveBookmarks(enrollmentId, payload) {
  await CourseEnrollmentApi.saveBookmarks(enrollmentId, payload);
}
