<template>
  <v-col cols="3" xl="2">
    <v-hover>
      <template #default="{ hover }">
        <v-card outlined>
          <v-img height="150" :src="course.image"></v-img>
          <v-card-title>
            <span class="text-truncate" style="width: 100%">
              {{ course.info.name }}
            </span>
          </v-card-title>
          <v-card-subtitle>{{ course.info.teacher }} </v-card-subtitle>
          <v-card-text v-if="userRole != 'MANAGER'">
            <v-row>
              <v-col cols="12" class="d-flex pb-0">
                Progress: {{ course.info.progress }}%
                <v-spacer />
                <div
                  v-if="course.info.progress == 100 && userRole != 'MANAGER'"
                >
                  Test:
                  <v-icon
                    v-for="(item, index) in attempts"
                    :key="index"
                    :color="item.color"
                    x-small
                    class="ml-1"
                  >
                    {{ item.icon }}
                  </v-icon>
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-progress-linear
                  :color="progressBarColor"
                  buffer-value="100"
                  :value="course.info.progress"
                />
              </v-col>
            </v-row>
          </v-card-text>
          <v-fade-transition>
            <v-overlay v-if="hover && showButtons" absolute color="#233642">
              <v-btn
                fab
                color="#233642"
                @click="goToCourseOverview(course.info.id)"
              >
                <v-icon large>mdi-information-symbol</v-icon>
              </v-btn>
              <v-btn
                color="#ec9436"
                class="ml-2"
                fab
                @click="goToCourseContinue(1, course.info.id)"
              >
                <v-icon large>mdi-play</v-icon>
              </v-btn>
            </v-overlay>
          </v-fade-transition>
        </v-card>
      </template>
    </v-hover>
  </v-col>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import { progressColor } from "@/support/colors";

export default {
  name: "CourseCard",
  props: {
    course: { type: Object, default: () => {} },
    showButtons: { type: Boolean, default: true },
  },
  data() {
    return {
      maxAttempts: 3,
      overlay: false,
    };
  },
  computed: {
    ...mapGetters({
      userRole: "user/userRole",
    }),
    attempts() {
      const parsedAttemps = [];
      const testNotDoneObj = {
        color: "grey",
        icon: "mdi-checkbox-blank-circle-outline",
      };

      for (let index = 0; index < this.maxAttempts; index++) {
        if (!this.course.attempts[index]) {
          parsedAttemps.push(testNotDoneObj);
        } else {
          let attemptObj = {
            color: this.course.attempts[index].passed ? "success" : "error",
            icon: "mdi-checkbox-blank-circle",
          };
          parsedAttemps.push(attemptObj);
        }
      }
      return parsedAttemps;
    },
    progressBarColor() {
      return progressColor(this.course.info.progress);
    },
  },
  methods: {
    ...mapMutations({
      setCourseContinueTab: "tab/_setCourseTab",
    }),
    goToCourseOverview(courseId) {
      this.$router.push({ name: "lesson-overview", params: { id: courseId } });
    },
    goToCourseContinue(tab, courseId) {
      this.setCourseContinueTab(tab);
      this.$router.push({ name: "lesson", params: { id: courseId } });
    },
  },
};
</script>
