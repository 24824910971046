<template>
  <section class="h-100 w-100">
    <div class="h-100 w-100">
      <v-tabs v-model="selectedTab" grow class="bc-none">
        <!-- TABS ------------------------------------------------>
        <div class="d-flex w-100" style="height: 50px">
          <!-- COURSES -->
          <v-tab active class="bc-lightGrey" @click="setNewTab(0)">
            Lessons ( {{ courses.length }} )
          </v-tab>
          <!-- STUDENTS -->
          <v-tab class="bc-lightGrey" @click="setNewTab(1)">
            Students ( {{ students.length }} )
          </v-tab>
          <!-- TEACHERS -->
          <v-tab
            v-if="userRole == 'ADMIN'"
            class="bc-lightGrey"
            @click="setNewTab(2)"
          >
            Teachers ( {{ teachers.length }} )
          </v-tab>
          <!-- DOWNLOAD EXCEL -->
          <v-tab
            v-if="userRole == 'ADMIN' || userRole == 'MANAGER'"
            class="bc-lightGrey"
            @click="setNewTab(0), downloadExcel()"
          >
            Report For {{ getSelectedSemester?.text }}
          </v-tab>
        </div>
      </v-tabs>

      <v-tabs-items
        v-model="selectedTab"
        class="w-100"
        style="calc(100% - 50px)"
      >
        <!-- COURSES -->
        <v-tab-item class="w-100 pa-5 h-100 overflow-auto h-100 bc-lightGrey">
          <v-data-table
            class="pb-2"
            :search="searchLesson"
            :fixed-header="true"
            :headers="coursesHeaders"
            :items="courses"
            item-key="id"
            show-expand
            disable-pagination
            :hide-default-footer="true"
            style="height: calc(100vh - 160px)"
          >
            <template #top>
              <v-text-field
                v-model="searchLesson"
                label="Search lesson"
                class="mx-4"
                color="hsl(31, 83%, 57%)"
                append-icon="mdi-magnify"
              />
            </template>
            <template #[`item.teacher`]="{ item }">
              <router-link
                class="text-decoration-none"
                :to="{
                  name: 'teacher-info',
                  params: { teacherId: item.teacher.id },
                }"
              >
                {{ item.teacher.name }} {{ item.teacher.surname }}
              </router-link>
            </template>

            <template #[`item.data-table-expand`]="{ expand, isExpanded }">
              <v-tooltip top>
                <template #activator="{ on, attrs }">
                  <div
                    v-bind="attrs"
                    class="d-flex pointer"
                    v-on="on"
                    @click="
                      expand(!isExpanded);
                      resetInfo();
                    "
                  >
                    <v-icon class="c-iasonOrange font-25">
                      mdi-account-group
                    </v-icon>
                    <v-icon class="c-lightBlue font-25">
                      mdi-chevron-down
                    </v-icon>
                  </div>
                </template>
                <span class="font-12">Toggle Students List</span>
              </v-tooltip>
            </template>

            <template #expanded-item="{ headers, item }">
              <td
                :colspan="headers.length"
                class="lessonList overflow-auto"
                style="max-height: 50%"
              >
                <ul class="pa-3" style="max-height: 250px">
                  <li
                    v-if="item.students.length == 0"
                    class="lessonLine pa-2 d-flex justify-space-between align-center reset-list-style"
                    style="width: 700px"
                  >
                    <h2 class="font-12 font-weight-regular c-lightBlue">
                      No Students Enrolled
                    </h2>
                  </li>
                  <li
                    v-for="(student, i) in item.students"
                    :key="i"
                    class="lessonLine pa-2 d-flex justify-start align-center reset-list-style lightgrey-border-bottom"
                    style="width: 700px"
                  >
                    <v-btn
                      class="mr-5"
                      color="#233642"
                      icon
                      :to="{
                        name: 'student-info',
                        params: { studentId: student.id },
                      }"
                    >
                      <v-icon>mdi-account-circle</v-icon>
                    </v-btn>
                    <div class="mr-5" style="width: 200px">
                      <span class="font-15">
                        {{ student.name }} {{ student.surname }}
                      </span>
                    </div>

                    <div class="d-flex justify-start align-center mr-5">
                      <v-progress-linear
                        class="mr-1"
                        style="width: 100px"
                        :color="progressBarColor(student.courseInfo.progress)"
                        background-color="#F4F6F9"
                        buffer-value="100"
                        :value="student.courseInfo.progress"
                        height="5"
                      />
                      <div class="font-10">
                        {{ student.courseInfo.progress.toFixed() }} %
                      </div>
                    </div>

                    <v-tooltip v-if="!testInfo" right>
                      <!--  -->
                      <template #activator="{ on, attrs }">
                        <div
                          v-bind="attrs"
                          class="d-flex pointer"
                          style="transition: all 0.5s linear"
                          v-on="on"
                          @click="toggleTestInfo()"
                        >
                          <div
                            v-for="test in student.courseInfo.tests"
                            :key="test"
                            class="mr-5 d-flex"
                            style="transition: all 0.5s linear"
                          >
                            <v-icon
                              :class="
                                test.score >= 60 ? 'c-success ' : 'c-danger '
                              "
                              class="font-10"
                              style="transition: all 0.5s linear"
                            >
                              mdi-checkbox-blank-circle
                            </v-icon>
                          </div>
                          <div
                            v-for="index in 3 - student.courseInfo.tests.length"
                            :key="index"
                            class="mr-5 d-flex"
                            style="transition: all 0.5s linear"
                          >
                            <v-icon
                              class="c-lightBlue font-10"
                              style="transition: all 0.5s linear"
                            >
                              mdi-checkbox-blank-circle-outline
                            </v-icon>
                          </div>
                        </div>
                      </template>
                      <span class="font-12">Toggle test results</span>
                    </v-tooltip>

                    <div
                      v-else
                      class="d-flex pointer"
                      style="transition: all 0.5s linear"
                      @click="toggleTestInfo()"
                    >
                      <div
                        v-for="index in student.courseInfo.tests.length"
                        :key="index"
                        class="mr-5 d-flex"
                        style="width: 100px; border-right: 1px solid #3d5463"
                      >
                        <v-icon
                          :class="
                            student.courseInfo.tests[index - 1].score >= 60
                              ? 'c-success '
                              : 'c-danger '
                          "
                          class="font-10"
                          style="transition: all 0.5s linear"
                        >
                          mdi-checkbox-blank-circle
                        </v-icon>
                        <div v-if="testInfo" class="testInfo">
                          <span class="font-weight-bold mr-3"
                            >T{{ index }}</span
                          >
                          <span
                            >{{ student.courseInfo.tests[index - 1].score }} /
                            100</span
                          >
                        </div>
                      </div>
                      <div
                        v-for="index in 3 - student.courseInfo.tests.length"
                        :key="index"
                        class="mr-5 d-flex"
                        style="width: 100px; border-right: 1px solid #3d5463"
                      >
                        <v-icon
                          class="c-lightBlue font-10"
                          style="transition: all 0.5s linear"
                        >
                          mdi-checkbox-blank-circle-outline
                        </v-icon>
                        <div v-if="testInfo" class="testInfo">
                          <span class="font-weight-bold mr-3"
                            >T{{
                              student.courseInfo.tests.length + index
                            }}</span
                          >
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </td>
            </template>

            <template #[`item.completed`]="{ item }">
              {{ item.passedEnrollmentsNumber }} / {{ item.enrollmentsNumber }}
            </template>

            <template #[`item.scoreAverage`]="{ item }">
              <span>{{ scoreAverageFormatter(item.scoreAverage) }}</span>
            </template>

            <template #[`item.action`]="{ item }">
              <div class="w-100 d-flex justify-space-around align-center">
                <v-btn color="#233642" icon @click="goToOverview(item.id)">
                  <v-icon>mdi-information-outline</v-icon>
                </v-btn>
              </div>
            </template>
          </v-data-table>
        </v-tab-item>

        <!-- STUDENTS -->
        <v-tab-item class="w-100 pa-5 h-100 overflow-auto h-100 bc-lightGrey">
          <v-data-table
            class="pb-2"
            :fixed-header="true"
            :headers="studentsHeaders"
            :items="students"
            :search="searchStudent"
            item-key="id"
            show-expand
            disable-pagination
            :hide-default-footer="true"
            style="height: calc(100vh - 160px)"
          >
            <template #top>
              <v-text-field
                v-model="searchStudent"
                label="Search student"
                class="mx-4"
                color="hsl(31, 83%, 57%)"
                append-icon="mdi-magnify"
              />
            </template>
            <template #[`item.enrolled`]="{ item }">
              <span> {{ item.courses.length }}</span>
            </template>

            <template #[`item.data-table-expand`]="{ expand, isExpanded }">
              <v-tooltip top>
                <template #activator="{ on, attrs }">
                  <div
                    v-bind="attrs"
                    class="d-flex pointer"
                    v-on="on"
                    @click="
                      expand(!isExpanded);
                      resetInfo();
                    "
                  >
                    <v-icon class="c-iasonOrange font-25">
                      mdi-movie-open-settings
                    </v-icon>
                    <v-icon class="c-lightBlue font-25">
                      mdi-chevron-down
                    </v-icon>
                  </div>
                </template>
                <span class="font-12">Toggle Lesson List</span>
              </v-tooltip>
            </template>

            <template #expanded-item="{ headers, item }">
              <td
                :colspan="headers.length"
                class="lessonList overflow-auto"
                style="max-height: 50%"
              >
                <ul class="pa-3" style="max-height: 250px">
                  <li
                    v-if="item.courses.length == 0"
                    class="lessonLine pa-2 d-flex justify-space-between align-center reset-list-style"
                    style="width: 700px"
                  >
                    <h2 class="font-12 font-weight-regular c-lightBlue">
                      No Lessons Available
                    </h2>
                  </li>
                  <li
                    v-for="(lesson, j) in item.courses"
                    :key="j"
                    class="lessonLine pa-2 d-flex justify-space-between align-center reset-list-style lightgrey-border-bottom"
                    style="width: 700px"
                  >
                    <v-btn
                      class="mr-2"
                      color="#233642"
                      icon
                      :to="{
                        name: 'lesson-overview',
                        params: { id: lesson.id },
                      }"
                    >
                      <v-icon>mdi-cast-education</v-icon>
                    </v-btn>
                    <v-tooltip right>
                      <template #activator="{ on, attrs }">
                        <span
                          v-bind="attrs"
                          class="mr-5 d-flex align-center"
                          style="width: 300px"
                          v-on="on"
                        >
                          <span
                            class="text-decoration-none text-truncate c-darkBlue"
                          >
                            {{ lesson.name }}
                          </span>
                        </span>
                      </template>
                      <span class="font-12">{{ lesson.name }}</span>
                    </v-tooltip>

                    <span
                      v-if="lesson.mandatory"
                      class="c-danger"
                      style="width: 80px"
                      >Mandatory</span
                    >
                    <span
                      v-else
                      v-bind="attrs"
                      class="c-info"
                      style="width: 80px"
                      v-on="on"
                      >Optional</span
                    >

                    <div class="d-flex justify-start align-center mr-5">
                      <v-progress-linear
                        class="mr-1"
                        style="width: 100px"
                        :color="progressBarColor(lesson.progress)"
                        background-color="#F4F6F9"
                        buffer-value="100"
                        :value="lesson.progress"
                        height="5"
                      />
                      <div class="font-10" style="width: 30px">
                        {{ lesson.progress.toFixed() }}%
                      </div>
                    </div>

                    <v-tooltip v-if="!testInfo" right>
                      <template #activator="{ on, attrs }">
                        <div
                          v-bind="attrs"
                          class="d-flex pointer"
                          style="transition: all 0.5s linear"
                          v-on="on"
                          @click="toggleTestInfo()"
                        >
                          <div
                            v-for="score in lesson.tests"
                            :key="score"
                            class="mr-5 d-flex"
                            style="transition: all 0.5s linear"
                          >
                            <v-icon
                              :class="
                                score.score >= 60 ? 'c-success ' : 'c-danger '
                              "
                              class="font-10"
                              style="transition: all 0.5s linear"
                            >
                              mdi-checkbox-blank-circle
                            </v-icon>
                          </div>
                          <div
                            v-for="index in 3 - lesson.tests.length"
                            :key="index"
                            class="mr-5 d-flex"
                            style="transition: all 0.5s linear"
                          >
                            <v-icon
                              class="c-lightBlue font-10"
                              style="transition: all 0.5s linear"
                            >
                              mdi-checkbox-blank-circle-outline
                            </v-icon>
                          </div>
                        </div>
                      </template>
                      <span class="font-12">Toggle Test Results</span>
                    </v-tooltip>

                    <div
                      v-else
                      class="d-flex pointer"
                      style="transition: all 0.5s linear"
                      @click="toggleTestInfo()"
                    >
                      <div
                        v-for="(score, index) in lesson.tests"
                        :key="index"
                        class="mr-5 d-flex"
                        style="width: 100px; border-right: 1px solid #3d5463"
                      >
                        <v-icon
                          :class="
                            score.score >= 60 ? 'c-success ' : 'c-danger '
                          "
                          class="font-10"
                          style="transition: all 0.5s linear"
                        >
                          mdi-checkbox-blank-circle
                        </v-icon>
                        <div v-if="testInfo" class="testInfo">
                          <span class="font-weight-bold mr-3"
                            >T{{ index + 1 }}</span
                          >
                          <span>{{ score.score }} / 100</span>
                        </div>
                      </div>
                      <div
                        v-for="index in 3 - lesson.tests.length"
                        :key="index"
                        class="mr-5 d-flex"
                        style="width: 100px; border-right: 1px solid #3d5463"
                      >
                        <v-icon
                          class="c-lightBlue font-10"
                          style="transition: all 0.5s linear"
                        >
                          mdi-checkbox-blank-circle-outline
                        </v-icon>
                        <div v-if="testInfo" class="testInfo">
                          <span class="font-weight-bold mr-3"
                            >T{{ lesson.tests.length + index }}</span
                          >
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </td>
            </template>

            <template #[`item.mandatory`]="{ item }">
              <v-tooltip :disabled="tooltipBar" top>
                <template #activator="{ on, attrs }">
                  <div
                    v-if="toggleBar"
                    class="w-100 pointer"
                    @click="toggleBars(false)"
                  >
                    <v-progress-linear
                      v-bind="attrs"
                      class="mr-1"
                      style="width: 100%"
                      :color="progressBarColor(item.mandatory)"
                      background-color="#F4F6F9"
                      buffer-value="100"
                      :value="item.mandatory"
                      height="5"
                      v-on="on"
                    />
                  </div>
                  <div
                    v-else
                    class="w-100 pointer"
                    :style="'color:' + progressBarColor(item.mandatory)"
                    @click="toggleBars(true)"
                  >
                    {{ item.mandatory.toFixed() }}%
                  </div>
                </template>
                <span class="font-10">{{ item.mandatory.toFixed() }}%</span>
              </v-tooltip>
            </template>

            <template #[`item.optional`]="{ item }">
              <v-tooltip :disabled="tooltipBar" top>
                <template #activator="{ on, attrs }">
                  <div
                    v-if="toggleBar"
                    class="w-100 pointer"
                    @click="toggleBars(false)"
                  >
                    <v-progress-linear
                      v-bind="attrs"
                      class="mr-1"
                      style="width: 100%"
                      :color="progressBarColor(item.optional)"
                      background-color="#F4F6F9"
                      buffer-value="100"
                      :value="item.optional"
                      height="5"
                      v-on="on"
                    />
                  </div>
                  <div
                    v-else
                    class="w-100 pointer"
                    :style="'color:' + progressBarColor(item.optional)"
                    @click="toggleBars(true)"
                  >
                    {{ item.optional.toFixed() }}%
                  </div>
                </template>
                <span class="font-10">{{ item.optional.toFixed() }}%</span>
              </v-tooltip>
            </template>

            <template #[`item.total`]="{ item }">
              <v-tooltip :disabled="tooltipBar" top>
                <template #activator="{ on, attrs }">
                  <div
                    v-if="toggleBar"
                    class="w-100 pointer"
                    @click="toggleBars(false)"
                  >
                    <v-progress-linear
                      v-bind="attrs"
                      class="mr-1"
                      style="width: 100%"
                      :color="progressBarColor(item.total)"
                      background-color="#F4F6F9"
                      buffer-value="100"
                      :value="item.total"
                      height="5"
                      v-on="on"
                    />
                  </div>
                  <div
                    v-else
                    class="w-100 pointer"
                    :style="'color:' + progressBarColor(item.total)"
                    @click="toggleBars(true)"
                  >
                    {{ item.total.toFixed() }}%
                  </div>
                </template>
                <span class="font-10">{{ item.total }}%</span>
              </v-tooltip>
            </template>

            <template #[`item.average`]="{ item }">
              <span>{{ scoreAverageFormatter(item.average) }}</span>
            </template>

            <template #[`item.completed`]="{ item }">
              <span> {{ item.completed }} / {{ item.courses.length }}</span>
            </template>

            <template #[`item.action`]="{ item }">
              <v-btn
                color="#233642"
                icon
                :to="{ name: 'student-info', params: { studentId: item.id } }"
              >
                <v-icon>mdi-information-outline</v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-tab-item>

        <!-- TEACHERS -->
        <v-tab-item
          v-if="userRole == 'ADMIN'"
          class="w-100 pa-5 h-100 overflow-auto h-100 bc-lightGrey"
        >
          <v-data-table
            class="pb-2"
            :fixed-header="true"
            :headers="teachersHeaders"
            :items="teachers"
            :search="searchTeacher"
            disable-pagination
            :hide-default-footer="true"
            style="height: calc(100vh - 160px)"
          >
            <template #top>
              <v-text-field
                v-model="searchTeacher"
                label="Search teacher"
                class="mx-4"
                color="hsl(31, 83%, 57%)"
                append-icon="mdi-magnify"
              />
            </template>
            <template #[`item.action`]="{ item }">
              <v-btn
                color="#233642"
                icon
                :to="{ name: 'teacher-info', params: { teacherId: item.id } }"
              >
                <v-icon>mdi-information-outline</v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-tab-item>

        <!-- DOWNLOAD EXCEL -->
        <v-tab-item
          v-if="userRole == 'ADMIN' || userRole == 'MANAGER'"
          class="w-100 pa-5 h-100 overflow-auto h-100 bc-lightGrey"
        >
          <div
            class="d-flex w-100 h-100 flex-column align-center justify-center"
          >
            <h1 class="font-50 c-darkBlue font-weight-bold">Download Report</h1>
            <h5 class="font-20 c-lightBlue">Download Will Begin Shortly</h5>
            <v-icon class="download-animate font-100 c-darkBlue">
              mdi-arrow-down-bold-outline
            </v-icon>
          </div>
        </v-tab-item>
      </v-tabs-items>
    </div>
  </section>
</template>

<script>
import { ExportService, TeamService } from "@/services";
import { mapActions, mapGetters, mapMutations } from "vuex";
import Utils from "@/support/utils.js";
import { progressColor } from "@/support/colors";
import { showErrorSnackbar } from "@/support/snackbar";
import { onlyDate } from "@/support/date";

export default {
  name: "TeamPage",
  data() {
    return {
      viewInfo: {
        text: "Team",
        disabled: false,
        exact: true,
        divider: "/",
        to: { name: `${this.$route.name}` },
        id: `${this.$route.name}`,
      },
      selectedTab: undefined,
      testInfo: false,
      searchLesson: "",
      searchStudent: "",
      searchTeacher: "",

      /* TEACHERS */
      teachersHeaders: [
        { text: "Name", value: "name", align: "start", sortable: true },
        { text: "Surname", value: "surname", align: "center", sortable: true },
        { text: "Level", value: "level", align: "center", sortable: false },
        {
          text: "Competence Center",
          value: "competenceCenter",
          align: "center",
          sortable: false,
        },
        {
          text: "Function",
          value: "function",
          align: "center",
          sortable: false,
        },
        {
          text: "Lessons",
          value: "courses.length",
          align: "center",
          sortable: false,
        },
        {
          text: "Students",
          value: "students",
          align: "center",
          sortable: true,
        },
        { text: "Action", value: "action", align: "center", sortable: false },
      ],

      teachers: [],
      loadingTeacher: true,

      /* STUDENTS */
      studentsHeaders: [
        { text: "Name", value: "name", align: "start", sortable: true },
        { text: "Surname", value: "surname", align: "center", sortable: true },
        { text: "Level", value: "level", align: "center", sortable: false },
        {
          text: "Competence Center",
          value: "competenceCenter",
          align: "center",
          sortable: false,
        },
        {
          text: "Function",
          value: "function",
          align: "center",
          sortable: false,
        },
        {
          text: "Enrolled",
          value: "enrolled",
          align: "center",
          sortable: false,
        },
        { text: "", value: "data-table-expand" },
        {
          text: "Mandatory",
          value: "mandatory",
          align: "center",
          sortable: true,
        },
        {
          text: "Optional",
          value: "optional",
          align: "center",
          sortable: false,
        },
        { text: "Total", value: "total", align: "center", sortable: true },
        {
          text: "Score average",
          value: "average",
          align: "center",
          sortable: false,
        },
        {
          text: "Completed",
          value: "completed",
          align: "center",
          sortable: true,
        },
        { text: "Action", value: "action", align: "center", sortable: false },
      ],

      students: [],
      loadingStudents: true,

      /* COURSES */
      coursesHeaders: [
        { text: "Name", value: "name", align: "start", sortable: true },
        { text: "Area", value: "area", align: "center", sortable: false },
        { text: "Teacher", value: "teacher", align: "center", sortable: true },
        {
          text: "Chapters",
          value: "sectionsNumber",
          align: "center",
          sortable: false,
        },
        {
          text: "Students",
          value: "enrollmentsNumber",
          align: "center",
          sortable: false,
        },
        { text: "", value: "data-table-expand" },
        {
          text: "Score Average",
          value: "scoreAverage",
          align: "center",
          sortable: false,
        },
        {
          text: "Completed",
          value: "completed",
          align: "center",
          sortable: true,
        },
        { text: "Action", value: "action", align: "center", sortable: false },
      ],

      courses: [],
      loadingCourses: true,

      toggleBar: true,
      tooltipBar: false,
    };
  },
  computed: {
    ...mapGetters({
      authorization: "user/authorization",
      userRole: "user/userRole",
      getTab: "tab/getTeamTab",
    }),
    ...mapGetters("semesters", {
      getSelectedSemester: "getSelectedSemester",
    }),
  },
  watch: {
    getSelectedSemester() {
      this.courses = [];
      this.loadingCourses = true;
      this.students = [];
      this.loadingStudents = true;
      this.teachers = [];
      this.loadingTeacher = true;
      this.loadInfo(this.getSelectedSemester.value);
    },
  },
  created: async function () {
    this.setSelectedTab();
    this.setFullHistoryItems(this.viewInfo);
    await this.loadInfo(this.getSelectedSemester.value);
  },
  async destroyed() {
    this.setIsDataLoaded(false);
  },
  methods: {
    ...mapMutations({
      setNewTab: "tab/_setTeamTab",
      setCourseTab: "tab/_setCourseTab",
    }),
    ...mapActions({
      setFullHistoryItems: "navigation/setFullHistoryItems",
      setIsDataLoaded: "setIsDataLoaded",
    }),
    async loadInfo(semesterId) {
      try {
        const [courses, students, teachers] = await Promise.all([
          TeamService.getCourses(semesterId),
          TeamService.getStudents(semesterId),
          TeamService.getTeachers(semesterId),
        ]);

        this.courses = courses;
        this.loadingCourses = false;

        this.students = students;
        this.loadingStudents = false;

        this.teachers = teachers;
        this.loadingTeacher = false;

        this.setIsDataLoaded(true);
      } catch (ex) {
        Utils.handle_401(ex);
        showErrorSnackbar({
          code: ex.response.status,
          message: `Something Went Wrong: ${ex.response.data.message}`,
        });
      }
    },
    progressBarColor(progress) {
      return progressColor(progress);
    },
    toggleTestInfo() {
      var lessonLine = document.getElementsByClassName("lessonLine");
      if (this.testInfo == false) {
        this.testInfo = true;
        this.on = true;
        for (let line of lessonLine) {
          line.style.width = "970px";
        }
      } else if (this.testInfo == true) {
        this.testInfo = false;
        this.on = false;
        for (let line of lessonLine) {
          line.style.width = "700px";
        }
      }
    },
    trim(date) {
      return onlyDate(date);
    },
    setSelectedTab() {
      this.selectedTab = this.getTab;
    },
    toggleBars(bool) {
      this.toggleBar = bool;
      if (bool == true) {
        this.tooltipBar = false;
      } else {
        this.tooltipBar = true;
      }
    },
    scoreAverageFormatter(score) {
      if (score <= 0) {
        return " ";
      } else {
        return score.toFixed() + " %";
      }
    },
    resetInfo() {
      var lessonLine = document.getElementsByClassName("lessonLine");
      for (let line of lessonLine) {
        line.style.width = "700px";
      }
      this.testInfo = false;
    },
    goToOverview(id) {
      this.$router.push({ name: "lesson-overview", params: { id: id } });
    },
    goToLesson(id) {
      this.setCourseTab(1);
      this.$router.push({ name: "lesson", params: { id: id } });
    },
    goToEditLesson(id) {
      this.$router.push({ name: "lesson-edit", params: { id: id } });
    },
    goToStudentInfo(id) {
      this.$router.push({ name: "student-info", params: { studentId: id } });
    },
    async downloadExcel() {
      try {
        await ExportService.getSemesterInfo(this.getSelectedSemester.value);
      } catch (ex) {
        showErrorSnackbar({
          code: ex.response.status,
          message: `Something Went Wrong: ${ex.response.data.message}`,
        });
      } finally {
        this.setSelectedTab();
      }
    },
  },
};
</script>
