<template>
  <v-row v-if="isDataLoaded">
    <v-col cols="12">
      <v-row>
        <v-col cols="12">
          <view-title-section label="Batch Students Enroll">
            <template #action-buttons>
              <v-btn
                color="error"
                text
                dark
                :disabled="
                  selectedStudents.length == 0 && selectedCourses.length == 0
                "
                class="mr-2"
                @click="onClearSelection"
              >
                <v-icon left> mdi-close </v-icon>
                Clear Selection
              </v-btn>
              <v-btn
                color="success"
                dark
                :disabled="
                  selectedStudents.length == 0 ||
                  selectedCourses.length == 0 ||
                  selectedSemester.length == 0
                "
                @click="onEnrollStudents"
              >
                <v-icon left> mdi-account-multiple-plus </v-icon>
                Enroll
              </v-btn>
            </template>
          </view-title-section>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          <v-card>
            <v-card-title>STUDENTS</v-card-title>
            <v-card-text>
              <v-row>
                <v-col cols="6">
                  <v-autocomplete
                    v-model="selectedCompetenceCenters"
                    :items="competenceCenters"
                    label="Competence Center"
                    placeholder="Filter by Competence Center"
                    persistent-placeholder
                    color="#2F4858"
                    background-color="white"
                    multiple
                    outlined
                    clearable
                    @change="onSelectedCompetenceCenterChanged"
                  >
                    <template #prepend-item>
                      <v-list-item ripple @click="toggleCompetenceCenter">
                        <v-list-item-content>
                          <v-list-item-title> Select All </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-divider class="mt-2"></v-divider>
                    </template>
                  </v-autocomplete>
                  <v-autocomplete
                    v-model="selectedStudents"
                    :items="viewableStudents"
                    label="Students"
                    placeholder="Select at least one student"
                    persistent-placeholder
                    color="#2F4858"
                    background-color="white"
                    multiple
                    outlined
                    clearable
                  >
                    <template #selection="{ index }">
                      <span v-if="selectedStudents.length === 1">
                        {{ selectedStudents[0].info.email }}
                      </span>
                      <span v-else-if="index === 1">
                        {{ selectedStudents.length }} students
                      </span>
                    </template>
                    <template #prepend-item>
                      <v-list-item ripple @click="toggleStudents">
                        <v-list-item-action>
                          <v-icon
                            :color="
                              selectedStudents.length > 0
                                ? 'indigo darken-4'
                                : ''
                            "
                          >
                            {{ iconStudents }}
                          </v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title> Select All </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-divider class="mt-2"></v-divider>
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col cols="6">
                  <v-responsive
                    height="calc(100vh - 300px)"
                    class="overflow-y-auto rounded pa-2"
                    style="background-color: #f5f5f5"
                  >
                    <v-card
                      v-for="(student, i) in selectedStudents"
                      :key="student.info.email"
                      flat
                      dark
                      color="#2F4858"
                      :class="{ 'mt-2': i > 0 }"
                    >
                      <v-card-text class="d-flex align-center">
                        {{ student.info.email }}
                        <v-spacer />
                        <v-btn icon small @click="onRemoveStudent(student)">
                          <v-icon small> mdi-close</v-icon>
                        </v-btn>
                      </v-card-text>
                    </v-card>
                  </v-responsive>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="6">
          <v-card>
            <v-card-title>
              <v-spacer />
              LESSONS
            </v-card-title>
            <v-card-text>
              <v-row>
                <v-col cols="6">
                  <v-responsive
                    height="calc(100vh - 300px)"
                    class="overflow-y-auto rounded pa-2"
                    style="background-color: #f5f5f5"
                  >
                    <v-card
                      v-for="(course, i) in selectedCourses"
                      :key="course.info.id"
                      flat
                      dark
                      color="#2F4858"
                      :class="{ 'mt-2': i > 0 }"
                    >
                      <v-card-text class="d-flex align-center">
                        {{ course.info.name }}
                        <v-spacer />
                        <v-btn icon small @click="onRemoveCourse(course)">
                          <v-icon small> mdi-close</v-icon>
                        </v-btn>
                      </v-card-text>
                    </v-card>
                  </v-responsive>
                </v-col>
                <v-col cols="6">
                  <v-autocomplete
                    v-model="selectedCourseArea"
                    class="w-100"
                    :items="courseAreas"
                    label="Lesson Area"
                    placeholder="Filter by Area"
                    persistent-placeholder
                    :color="'#2F4858'"
                    background-color="white"
                    outlined
                    clearable
                    @change="onSelectedCourseAreaChanged"
                  />
                  <v-autocomplete
                    v-model="selectedCourses"
                    :items="viewableCourses"
                    label="Lessons"
                    placeholder="Select at least one lesson"
                    persistent-placeholder
                    :color="'#2F4858'"
                    background-color="white"
                    multiple
                    outlined
                    clearable
                  >
                    <template #selection="{ index }">
                      <span v-if="selectedCourses.length === 1">
                        {{ selectedCourses[0].info.name }}
                      </span>
                      <span v-if="index === 1">
                        {{ selectedCourses.length }} lessons
                      </span>
                    </template>
                  </v-autocomplete>
                  <v-autocomplete
                    v-model="selectedSemester"
                    class="w-100"
                    :items="semesterItems"
                    label="Semester"
                    placeholder="Select a semester"
                    persistent-placeholder
                    :color="'#2F4858'"
                    background-color="white"
                    outlined
                  />
                  <v-switch
                    v-model="mandatory"
                    inset
                    color="#ec9436"
                    :label="mandatory ? 'Mandatory' : 'Optional'"
                    class="ml-1"
                  />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-col>
    <ConfirmationDialog
      v-model="confirmationDialog"
      :enrollment-results="enrollmentResults"
    />
  </v-row>
</template>

<script>
import { EnrollStudentsService } from "@/services";
import { mapActions, mapGetters } from "vuex";
import { showErrorSnackbar } from "@/support/snackbar";
import { semesterFromDate } from "@/support/date";
import ViewTitleSection from "@/components/global/ViewTitleSection.vue";
import ConfirmationDialog from "@/components/enroll-students/EnrollStudentsConfirmationDialog.vue";

export default {
  name: "EnrollStudents",
  components: { ViewTitleSection, ConfirmationDialog },
  data() {
    return {
      viewInfo: {
        text: "Enroll Students",
        disabled: false,
        exact: true,
        divider: "/",
        to: { name: `${this.$route.name}` },
        id: `${this.$route.name}`,
      },

      competenceCenters: [],
      courseAreas: [],
      mandatory: true,
      selectedCompetenceCenters: [],
      selectedCourseArea: undefined,
      selectedCourses: [],
      selectedSemester: null,
      selectedStudents: [],
      semesters: [],
      students: [],
      viewableCourses: [],
      viewableStudents: [],

      confirmationDialog: false,

      enrollmentResults: [],
    };
  },
  computed: {
    ...mapGetters({
      isDataLoaded: "isDataLoaded",
      authorization: "user/authorization",
    }),
    allCompetenceCentersSelected() {
      return (
        this.selectedCompetenceCenters.length === this.competenceCenters.length
      );
    },
    someCompetenceCentersSelected() {
      return (
        this.selectedCompetenceCenters.length > 0 &&
        !this.allCompetenceCentersSelected
      );
    },
    allStudentsSelected() {
      let ids = new Set(this.selectedStudents.map((s) => s.info.id));

      for (let viewableStudent of this.viewableStudents) {
        if (!ids.has(viewableStudent.value.info.id)) return false;
      }

      return true;
    },
    iconCompetenceCenters() {
      if (this.allCompetenceCentersSelected) return "mdi-close-box";
      if (this.someCompetenceCentersSelected) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },
    iconStudents() {
      if (this.allStudentsSelected) return "mdi-close-box";
      if (this.someStudentsSelected) return "mdi-minus-box";

      return "mdi-checkbox-blank-outline";
    },
    iconCourses() {
      if (this.selectedAllCourses) return "mdi-close-box";
      if (this.selectedSomeCourses) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },
    semesterItems() {
      return this.semesters.map((s) => {
        return { value: s, text: semesterFromDate(s.startDate) };
      });
    },
  },
  created: async function () {
    this.setFullHistoryItems(this.viewInfo);
  },
  mounted: async function () {
    await this.loadData();
  },
  async destroyed() {
    this.setIsDataLoaded(false);
  },
  methods: {
    ...mapActions({
      setFullHistoryItems: "navigation/setFullHistoryItems",
      setIsDataLoaded: "setIsDataLoaded",
    }),
    loadData: async function () {
      try {
        this.students = await EnrollStudentsService.getStudents();
        this.competenceCenters = this.distinct(
          this.students.map((s) => {
            return { value: s.competenceCenter, text: s.competenceCenter.name };
          })
        );

        this.viewableStudents = this.formatStudents(this.students);

        this.courses = await EnrollStudentsService.getCourses();
        this.courseAreas = this.distinct(
          this.courses.map((c) => {
            return { value: c.courseArea, text: c.courseArea.name };
          })
        );

        this.viewableCourses = this.formatCourses(this.courses);

        this.semesters = await EnrollStudentsService.getSemesters();

        this.selectedSemester = Object.assign(
          {},
          this.selectedSemester,
          this.semesters[0]
        );

        this.setIsDataLoaded(true);
      } catch (ex) {
        showErrorSnackbar({
          code: ex.response.status,
          message: `Something Went Wrong: ${ex.response.data.message}`,
        });
      }
    },
    onSelectedCompetenceCenterChanged(selectedCompetenceCenters) {
      this.viewableStudents = this.formatStudents(
        this.filterStudents(this.students, selectedCompetenceCenters)
      );
    },
    toggleCompetenceCenter() {
      this.$nextTick(() => {
        if (
          this.selectedCompetenceCenters.length == this.competenceCenters.length
        ) {
          this.selectedCompetenceCenters = [];
        } else {
          this.selectedCompetenceCenters = this.competenceCenters.map(
            (cc) => cc
          );
        }

        this.viewableStudents = this.formatStudents(this.students);
      });
    },
    toggleStudents() {
      this.$nextTick(() => {
        if (this.allStudentsSelected) {
          this.selectedStudents = [];
        } else {
          this.selectedStudents = this.filterStudents(
            this.students,
            this.selectedCompetenceCenters
          );
        }
      });
    },
    onSelectedCourseAreaChanged(selectedCourseArea) {
      this.viewableCourses = this.formatCourses(
        this.filterCourses(this.courses, selectedCourseArea)
      );
    },
    filterStudents(students, selectedCompetenceCenters) {
      if (selectedCompetenceCenters.length == 0) return students;

      let ids = new Set(selectedCompetenceCenters.map((cc) => cc.id));
      return students.filter((s) => ids.has(s.competenceCenter.id));
    },
    formatStudents(students) {
      return students.map((s) => {
        return { value: s, text: s.info.email };
      });
    },
    filterCourses(courses, selectedCourseArea) {
      if (selectedCourseArea == undefined) return courses;

      return courses.filter((s) => s.courseArea.id == selectedCourseArea.id);
    },
    formatCourses(courses) {
      return courses.map((c) => {
        return { value: c, text: c.info.name };
      });
    },
    distinct(items) {
      var distinct = [];

      for (let item of items) {
        if (distinct.filter((i) => i.value.id == item.value.id).length == 0) {
          distinct.push(item);
        }
      }

      return distinct;
    },
    onRemoveStudent(student) {
      this.selectedStudents = this.selectedStudents.filter(
        (s) => s.info.id != student.info.id
      );
    },
    onRemoveCourse(course) {
      this.selectedCourses = this.selectedCourses.filter(
        (c) => c.info.id != course.info.id
      );
    },
    onClearSelection() {
      this.selectedStudents = [];
      this.selectedCourses = [];
      this.selectedSemester = Object.assign(
        {},
        this.selectedSemester,
        this.semesters[0]
      );
    },
    async onEnrollStudents() {
      try {
        let payload = {
          students: this.selectedStudents.map((s) => s.info.id),
          courses: this.selectedCourses.map((c) => c.info.id),
          semester: this.selectedSemester.id,
          mandatory: this.mandatory,
        };

        const response = await EnrollStudentsService.enrollStudents(payload);
        this.enrollmentResults = response.data;
        this.confirmationDialog = true;
        this.onClearSelection();
      } catch (ex) {
        showErrorSnackbar({
          code: ex.response.status,
          message: `Something Went Wrong: ${ex.response.data.message}`,
        });
      }
    },
  },
};
</script>
