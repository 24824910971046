import { UserApi } from "@/api";
import { showSuccessSnackbar } from "@/support/snackbar";

export async function getAllUsers() {
  const { data } = await UserApi.getAllUsers("users");
  return data;
}

export async function getSingleUser(userId, infoType, semesterId) {
  const { data } = await UserApi.getSingleUser(userId, infoType, semesterId);
  return data;
}

export async function deleteAllUserLesson(studentObject) {
  const response = await UserApi.deleteAllUserLesson(studentObject.studentId);
  const snackBarMessage = `Successfully removed ${
    studentObject.studentName + " " + studentObject.studentSurname
  } from all lessons`;
  showSuccessSnackbar({
    code: response.status,
    message: snackBarMessage,
  });
}

export async function deleteUserSingleLesson(
  studentObject,
  courseInstanceId,
  lessonName
) {
  const response = await UserApi.deleteUserSingleLesson(
    studentObject.studentId,
    courseInstanceId
  );
  const snackBarMessage = `Successfully removed ${
    studentObject.studentName + " " + studentObject.studentSurname
  } from ${lessonName}`;
  showSuccessSnackbar({
    code: response.status,
    message: snackBarMessage,
  });
}
