<template>
  <v-row v-if="isDataLoaded">
    <v-col cols="12">
      <ViewTitleSection :label="viewInfo.text" />
    </v-col>
    <v-col cols="12">
      <v-card>
        <v-responsive :height="responsiveHeight" class="overflow-y-auto">
          <v-row v-if="courses.ongoing.length == 0" class="ma-3">
            <v-col cols="12">
              <v-card flat>
                <v-card-title> No favourites courses found </v-card-title>
              </v-card>
            </v-col>
          </v-row>
          <v-row v-else class="ma-3">
            <CourseCard
              v-for="(course, index) in courses.ongoing"
              :key="index"
              :course="course"
            />
          </v-row>
        </v-responsive>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Utils from "@/support/utils.js";
import CourseCard from "@/components/cards/CourseCard.vue";
import ViewTitleSection from "@/components/global/ViewTitleSection.vue";
import { showErrorSnackbar } from "@/support/snackbar";
import { YourFavouritesService } from "@/services";

export default {
  name: "YourFavouritesView",
  components: {
    CourseCard,
    ViewTitleSection,
  },
  data() {
    return {
      viewInfo: {
        text: "My Favourites",
        disabled: false,
        exact: true,
        divider: "/",
        to: { name: `${this.$route.name}` },
        id: `${this.$route.name}`,
      },

      courses: undefined,
    };
  },
  computed: {
    ...mapGetters({
      isDataLoaded: "isDataLoaded",
    }),
    responsiveHeight() {
      return "calc(100vh - 220px)";
    },
  },
  created: async function () {
    this.setFullHistoryItems(this.viewInfo);
    await this.loadCourses();
  },
  async destroyed() {
    this.setIsDataLoaded(false);
  },
  methods: {
    ...mapActions({
      setFullHistoryItems: "navigation/setFullHistoryItems",
      setIsDataLoaded: "setIsDataLoaded",
    }),
    loadCourses: async function () {
      try {
        this.courses = await YourFavouritesService.get();
        this.setIsDataLoaded(true);
      } catch (ex) {
        Utils.handle_401(ex);
        showErrorSnackbar({
          code: ex.response.status,
          message: `Something Went Wrong: ${ex.response.data.message}`,
        });
      }
    },
  },
};
</script>
