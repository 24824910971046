import { DocumentationApi } from "@/api";
import { downloadFile } from "@/support/file";

export async function getByUserId(userId, semesterId = null) {
  let query = {};
  if (semesterId) {
    query = {
      params: { semester: semesterId },
    };
  }
  const { data } = await DocumentationApi.getByUserId(userId, query);
  return data;
}

export async function download(fileId) {
  const { data } = await DocumentationApi.download(fileId);
  downloadFile(data, "File has been correctly downloaded");
}
